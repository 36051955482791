import * as actionType from 'actions';

const initialState = {
    isJobApplicationPdfLoading: false,
    jobApplicationPdfDetails: {},
    errorMessage: '',
    errorStatus: false
};

const jobApplicationPdf = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_JOB_APPLICATION_PDF: {
            return {
                ...state,
                isJobApplicationPdfLoading: true,
            };
        }

        case actionType.GET_JOB_APPLICATION_PDF_SUCCESS: {
            return {
                ...state,
                isJobApplicationPdfLoading: false,
                jobApplicationPdfDetails: action.payload,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isJobApplicationPdfLoading: false
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        default: {
            return state;
        }
    }
};

export default jobApplicationPdf;