import * as actionType from 'actions';

const initialState = {
    isApplicationConfirmationLoading: false,
    applicationConfirmationDetails: {},
    errorStatus: false,
    errorMessage:''
};

const applicationConfirmation = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_CONFIRMED_JOB_APPLICATION_DETAILS: {
            return {
                ...state,
                isApplicationConfirmationLoading: true,
            };
        }

        case actionType.GET_CONFIRMED_JOB_APPLICATION_DETAILS_SUCCESS: {
            return {
                ...state,
                isApplicationConfirmationLoading: false,
                applicationConfirmationDetails: action.payload,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isApplicationConfirmationLoading: false
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }
        default: {
            return state;
        }
    }
};

export default applicationConfirmation;