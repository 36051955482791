import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';

function* getCountrySaga() {
    try {
        const response = yield call(services.getCountryServices);
        yield put(actionType.getCountrySuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetCountrySaga() {
    yield takeLatest(actionType.GET_COUNTRY, getCountrySaga);
}

function* getStateSaga(stateInfo) {
    try {
        const response = yield call(services.getStateServices, stateInfo.payload);
        yield put(actionType.getStateSuccess({ "countryCode": stateInfo.payload.countryCode, "stateList": response.data.stateList }));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetStateSaga() {
    yield takeLatest(actionType.GET_STATE, getStateSaga);
}

function* getCountySaga(countyInfo) {
    try {
        const response = yield call(services.getCountyServices, countyInfo.payload);
        yield put(actionType.getCountySuccess({ "stateCode": countyInfo.payload.stateCode, "countyList": response.data.countyList }));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetCountySaga() {
    yield takeLatest(actionType.GET_COUNTY, getCountySaga);
}

function* sendMailOnLogoutSaga(request) {
    try {
        const response = yield call(services.sendMailOnLogoutServices, request.payload);
        yield put(actionType.sendMailOnLogoutSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchSendMailOnLogoutSaga() {
    yield takeLatest(actionType.SEND_MAIL_ON_LOGOUT, sendMailOnLogoutSaga);
}

const common = [
    fork(watchGetCountrySaga),
    fork(watchGetStateSaga),
    fork(watchGetCountySaga),
    fork(watchSendMailOnLogoutSaga)
];

export default common;