import { combineReducers } from 'redux';
import auth from './auth';
import jobApplication from './jobApplication';
import common from './common';
import review from './review';
import wizard from './wizard';
import uploadDocument from './uploadDocument';
import applicationConfirmation from './applicationConfirmation';
import jobApplicationPdf from './jobApplicationPdf';
import disclosures from './disclosure';

const rootReducer = combineReducers({
    auth,
    jobApplication,
    common,
    review,
    wizard,
    uploadDocument,
    applicationConfirmation,
    jobApplicationPdf,
    disclosures
});

export default rootReducer;