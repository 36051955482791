import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';
import { DecryptResponseData } from 'EncryptionDecryption/EncryptAndDecrypt';

function* getReviewInfoSaga(reviewInfo) {
    try {
        const response = yield call(services.getReviewInfoServices, reviewInfo);

        response.data = DecryptResponseData(response.data, "getReviewInfo"); 

        yield put(actionType.getReviewInfoSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetReviewInfoSaga() {
    yield takeLatest(actionType.GET_REVIEW_INFO, getReviewInfoSaga);
}

function* postReviewInfoSaga(reviewInfo) {
    try {
        const response = yield call(services.postReviewInfoServices, reviewInfo);
        response.data = DecryptResponseData(response.data, "postReviewInfo"); 
        yield put(actionType.postReviewInfoSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchPostReviewInfoSaga() {
    yield takeLatest(actionType.POST_REVIEW_INFO, postReviewInfoSaga);
}

const review = [
    fork(watchGetReviewInfoSaga),
    fork(watchPostReviewInfoSaga),
];

export default review;