import * as actionType from 'actions';

export const getJobDetailsLocal = () => ({
    type: actionType.GET_JOB_DETAILS_LOCAL,
});

export const getJobDetailsPost = (jobInfo) => ({
    type: actionType.GET_JOB_DETAILS,
    payload: jobInfo,
});

export const getJobDetailsPostSuccess = (jobStatus) => ({
    type: actionType.GET_JOB_DETAILS_SUCCESS,
    payload: jobStatus
});

export const getExistingUser = (data) => ({
    type: actionType.GET_EXISTING_USER,
    payload: data,
});

export const getExistingUserSuccess = (data) => ({
    type: actionType.GET_EXISTING_USER_SUCCESS,
    payload: data
});