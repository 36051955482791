export const months = [
	{
		"id": "1",
		"name": "January"
	},
	{
		"id": "2",
		"name": "February"
	},
	{
		"id": "3",
		"name": "March"
	},
	{
		"id": "4",
		"name": "April"
	},
	{
		"id": "5",
		"name": "May"
	},
	{
		"id": "6",
		"name": "June"
	},
	{
		"id": "7",
		"name": "July"
	},
	{
		"id": "8",
		"name": "August"
	},
	{
		"id": "9",
		"name": "September"
	},
	{
		"id": "10",
		"name": "October"
	},
	{
		"id": "11",
		"name": "November"
	},
	{
		"id": "12",
		"name": "December"
	}
];

const currentYear = (new Date()).getFullYear();
const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
const rangeString = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => (start + (i * step)).toString());

export const years100 = range(currentYear, currentYear - 100, -1);
export const years = rangeString(currentYear, currentYear - 9, -1);
export const accidentYears = rangeString(currentYear, currentYear - 3, -1);
export const futureYears = rangeString(currentYear + 10, currentYear, -1).reverse();
export const getFutureYearsByRange = (futureRange=1)=>rangeString(currentYear + futureRange, currentYear, -1).reverse();
export const canadaAccidentYears = rangeString(currentYear, currentYear - 5, -1);
export const canadaConvictedYears = rangeString(currentYear, currentYear - 6, -1);

export const monthDay = [
	'01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
	'11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
	'21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
	'31'
];

export const duration = {
	fromMonth: "FromMonth",
	fromYear: "FromYear",
	toMonth: "ToMonth",
	toYear: "ToYear"
};

export const fieldMaxLength = {
	firstNameMaxLength: 50,
	lastNameMaxLength: 50,
	middleNameMaxLength: 50,
	emailMaxLength: 254,
	phoneMaxLength: 50,
	addressMaxLength: 150,
	countryMaxLength: 100,
	cityMaxLength: 100,
	stateMaxLength: 100,
	countyMaxLength: 100,
	zipCodeMaxLength: 10,
	employerNameMaxLength: 150,
	positionHeldMaxLength: 100,
	employmentStatusMaxLength: 100,
	licenseNumberMaxLength: 15,
	socialSecurityNumberMinLength: 9,
	socialSecurityNumberMaxLength: 20,
	notesMaxLength: 255,
	otherTextMaxLength: 100,
	documentTitle: 100,
	otherEndorsementLength: 100,
	otherRestrictionsLength: 100,
	accurateMiddleNameMaxLength: 25,
	accurateAddressMaxLength: 100,
	accurateCityMaxLength: 50,
};

export const fieldTypeName = {
	firstName: "FirstName",
	lastName: "LastName",
	middleName: "MiddleName",
	phoneNumber: "PhoneNumber",
	address: "Address",
	zipCode: "ZIPCode",
	city: "City",
	state: "State",
	country: "Country",
	county: "County",
	licenseNumber: "LicenseNumber",
	employerName: "EmployerName",
	positionHeld: "PositionHeld",
	otherEmploymentStatus: "OtherEmploymentStatus",
	unemploymentReason: "UnemploymentReason",
	otherEquipmentClass: "OtherEquipmentClass",
	otherNatureOfAccident: "OtherNatureOfAccident",
	otherViolations: "OtherViolations",
	otherDrivingLicenseNumber: "OtherDrivingLicenseNumber",
	notes: "Notes",
	email: "EMail",
	socialSecurityNumber: "SocialSecurityNumber",
	identificationCountry: "identificationCountry"
};

export const penalties = [
	{
		"id": "1",
		"name": "Fined"
	},
	{
		"id": "2",
		"name": "Imprisoned"
	},
	{
		"id": "3",
		"name": "License Points Assessed"
	},
	{
		"id": "4",
		"name": "License Suspended"
	},
	{
		"id": "5",
		"name": "Ticketed"
	},
	{
		"id": "6",
		"name": "Traffic School"
	}
];

export const vehicleClass = [
	{
		"id": 44,
		"value": "CDL-A"
	},
	{
		"id": 45,
		"value": "CDL-B"
	},
	{
		"id": 46,
		"value": "CDL-C"
	},
	{
		"id": 47,
		"value": "CDL-Permit"
	},
	{
		"id": 48,
		"value": "Non-CDL"
	},
	{
		"id": 49,
		"value": "Other"
	}
];

export const numberCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const requestParameterForLUT = {
	employmentHistory: "employmentHistory",
	experienceAndQualification: "experienceAndQualification"
};