import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    colors
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import bytesToSize from 'utils/bytesToSize';
import { toBase64 } from 'utils/settings';
import { resetUpload } from 'actions'

const useStyles = makeStyles(theme => ({
    root: {},
    dropZone: {
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(6),
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.grey[50],
            opacity: 0.5,
            cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    dragActive: {
        backgroundColor: colors.grey[50],
        opacity: 0.5
    },
    image: {
        width: 130,
        [theme.breakpoints.down('xs')]: {
            height: theme.spacing(8),
            width: theme.spacing(8),
        },
    },
    info: {
        marginTop: theme.spacing(1)
    },
    list: {
        maxHeight: 320,
        [theme.breakpoints.down('xs')]:{
            overflowWrap: 'break-word',
            zoom: '0.9',
        },
    },
    actions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        '& > * + *': {
            marginLeft: theme.spacing(2)
        },
    },
    fileSizeLimit: {
        padding: '20px',
        marginBottom: '10px',
        background: '#FFE0E2',
        borderRadius: '3px',
        textAlign: 'center'
    },
}));

const FilesDropzone = props => {
    const { 
        className, 
        data, 
        initialValues,
        isAutoUpload,
        onAutomaticUpload,
        onUpload, 
        setFieldValue, 
        values, 
        ...rest
    } = props;
    const classes = useStyles();
    const [isFileSizeLimitExeed, setIsFileSizeLimitExeed] = useState(false);
    const dispatch = useDispatch();
    const MAXFILESIZE = 5242881;
    const [file, setFile] = useState(initialValues?.documentFile !== '' 
        ? [{name: initialValues?.fileName, size: initialValues?.fileSize}] 
        :[]
    );

    const handleFile = (acceptedFile, base64) => {
        setFieldValue("documentFile", base64);
        setFieldValue("fileName", acceptedFile[0]?.name);
        setFieldValue("fileSize", bytesToSize(acceptedFile[0]?.size));

        if(isAutoUpload){
            onUpload && onUpload({
                ...values, 
                documentFile: base64, 
                fileName: acceptedFile[0]?.name, 
                fileSize:bytesToSize(acceptedFile[0]?.size)
            });
            onAutomaticUpload && onAutomaticUpload(acceptedFile[0]?.name, acceptedFile[0]?.size, base64);

        }
    }

    const base64file = async (acceptedFile) => {
        const base64 = await toBase64(acceptedFile[0]);
        handleFile(acceptedFile,base64);
    };

    const handleDrop = useCallback(acceptedFile => {

        //  File size should be less than 5 MB
        if (acceptedFile[0].size < MAXFILESIZE) {
            setIsFileSizeLimitExeed(false);
            setFile(acceptedFile);
            setFieldValue("documentFile", acceptedFile);

            base64file(acceptedFile);
        }
        else {
            setIsFileSizeLimitExeed(true);
        }
    }, []);

    useEffect(() => {
        if (
            data.uploadResponse.status === true 
            && data?.uploadDoc?.documentValidationMessages.length === 0
        ) {
            setFile([]);
            setFieldValue("documentFile", '');
            setFieldValue("fileName", '');
            setFieldValue("fileSize", '');
            dispatch(resetUpload)
        }
    }, [data.uploadResponse]);

    const handleRemove = () => {
        setFile([]);
        setFieldValue("documentFile", '');
        setFieldValue("fileName", '');
        setFieldValue("fileSize", '');
        setIsFileSizeLimitExeed(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        accept: 'image/jpeg, image/png, .pdf',
        disabled: file.length === 0 ? false : true,
        onDrop: handleDrop
    });

    return (
        <div  {...rest} className={clsx(classes.root, className)}>
            {file?.length === 0 && 
                (<div className={clsx({ [classes.dropZone]: true, [classes.dragActive]: isDragActive })} {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    <img
                        alt="Select file"
                        className={classes.image}
                        src="/images/undraw_add_file2_gvbb.svg"
                    />
                </div>
                <div>
                    <Typography gutterBottom variant="h3" align='center'>
                        Upload file
                    </Typography>
                    <Typography className={classes.info} color="textSecondary" variant="body1" align='center'>
                        Drop files here or click {' '}
                        <Link underline="always">browse</Link>
                        {' '}to select a document
                    </Typography>
                    <Typography className={classes.info} color="textSecondary" variant="body2" align='center'>
                        (Only .jpg, .png, and .pdf file formats will be accepted)
                    </Typography>
                    <Typography className={classes.info} color="textSecondary" variant="body2" align='center'>
                        (Maximum File size should be 5 MB)
                    </Typography>
                </div>
            </div>
            )}
            { isFileSizeLimitExeed && (
                <p className={classes.fileSizeLimit}>
                    Unable to upload this file because the size exceeds the maximum size allowed which is 5 MB.
                </p>)
            }

            {file.length > 0 && (
                <Fragment>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                            {file.map((file, i) => (
                                <ListItem divider={i < file.length - 1} key={uuid()}>
                                    <ListItemIcon>
                                        <FileCopyIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        primaryTypographyProps={{ variant: 'h5' }}
                                        secondary={bytesToSize(file.size)}
                                    />
                                    <Button onClick={handleRemove} variant="contained" color="secondary" size="small">
                                        Remove
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                        <div className={classes.actions}>
                        </div>
                    </PerfectScrollbar>
                </Fragment>
            )}
        </div>
    );
};

FilesDropzone.propTypes = {
    className: PropTypes.string
};

export default FilesDropzone;