import React from 'react';
import { Link, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
link: {
    marginLeft: theme.spacing(0.5),
},
terms: {
    color: theme.palette.black,
    padding: theme.spacing(2, 1, 0, 1),
}
}));

const TermsOfUse = () => {
    const classes = useStyles();
       
    return(
        <Typography variant="body1" className={classes.terms}>
            By signing into J. J. Keller Applicant Center, you agree to the
            <Link
                color="secondary"
                underline="always"
                target="_blank"
                href='https://www.jjkeller.com/learn/terms-conditions'
                className={classes.link}
            >
                Terms of Use
            </Link>
            . For information about how we use any personal information we collect for our own purposes, please review our
            <Link
                color="secondary"
                underline="always"
                target="_blank"
                href='https://www.jjkeller.com/learn/notice-at-collection'
                className={classes.link}
            >
                Notice at Collection
            </Link>
            .
    </Typography>
    )
};

export default TermsOfUse;