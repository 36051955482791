import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx';

const useStyles = makeStyles({
    paper: { 
    }
});

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    title: {
        color: "#000",
        [theme.breakpoints.down('xs')]: {
            overflowWrap: 'break-word',
            width: '90%',
        }
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { 
        children, 
        classes, 
        onClose,
        titleClassName,
        titleContainerClassName, 
        closeIconClassName,
        ...other
    } = props;

    return (
        <MuiDialogTitle disableTypography className={clsx(classes.root, titleContainerClassName)} {...other}>
            <Typography className={clsx(classes.title, titleClassName)} variant="h3">
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={clsx(classes.closeButton, closeIconClassName)}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        overflowX: 'hidden'
    }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        justifyContent: 'center',
        padding: theme.spacing(1)
    }
}))(MuiDialogActions);

const ModalCustom = props => {
    const classes = useStyles();
    const { 
        children, 
        title, 
        submit,
        titleClassName, 
        open, 
        close, 
        actions, 
        backDrop, 
        width,
        titleContainerClassName, 
        buttonText, 
        buttonType,
        okClick, 
        cancelClick, 
        okButtonText, 
        cancelButtonText, 
        isDividersRequired = true,
        contentClassName,
        closeIconClassName,
    } = props
    
    return (
        <div>
            <Dialog 
                aria-labelledby="simple-modal-title" 
                classes={{ paper: classes.paper }}
                disableBackdropClick={backDrop} 
                fullWidth={true} 
                maxWidth={width} 
                onClose={close} 
                open={open} 
            >
                <DialogTitle 
                    id="simple-modal-title" 
                    onClose={close} 
                    titleClassName={titleClassName} 
                    titleContainerClassName={titleContainerClassName}
                    closeIconClassName={closeIconClassName}
                >
                    {title}
                </DialogTitle>
                <DialogContent 
                    className={contentClassName} 
                    dividers={isDividersRequired}
                >
                    {children}
                </DialogContent>
                {actions ?
                    (
                        <DialogActions>
                            {buttonText ?
                                <Button id={props.id} type={buttonType == 'submit' ? 'submit' : 'button'} onClick={e => submit(e)} name={props.name} variant="contained" color="primary">
                                    {buttonText}
                                </Button>
                                :
                                <>
                                    <Button type={buttonType == 'submit' ? 'submit' : 'button'} onClick={e => cancelClick(e)} name={'cancelButton'} variant="outlined" color="primary">
                                        {cancelButtonText}
                                    </Button>
                                    <Button type={buttonType == 'submit' ? 'submit' : 'button'} onClick={e => okClick(e)} name={"okButton"} variant="contained" color="primary">
                                        {okButtonText}
                                    </Button>
                                </>
                            }

                        </DialogActions>
                    ) : ''
                }
            </Dialog>
        </div>
    );
};

export default ModalCustom;