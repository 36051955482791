import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';;

const useStyles = makeStyles(theme => ({
    signButton: {
        backgroundColor: theme?.palette?.background?.lightBlue,
        border: '1px solid ' + theme?.palette?.grey?.outline,
        color: theme?.palette?.grey?.neutral,
        height: theme.spacing(10),
        textTransform: 'capitalize',
        width: theme.spacing(35),
        '&:hover': {
            backgroundColor: theme?.palette?.primary?.high,
            color: theme.palette.white,
            [theme.breakpoints.down('md')]: {
                backgroundColor: theme?.palette?.background?.lightBlue,
                color: theme?.palette?.grey?.neutral,
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

const SignHereButton = ({ className, onSignatureModalOpen }) => {
    const classes = useStyles();
   
    return (
        <Button
            className={clsx(classes.signButton, className)}
            variant="contained"
            onClick={onSignatureModalOpen}
        >
            Sign Here
        </Button>
                                    
    );
};

SignHereButton.propTypes = {
    onSignatureModalOpen: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default SignHereButton;