import React, { Suspense } from 'react';
import { renderRoutes } from 'utils/routerConfig';
import PropTypes from 'prop-types';
import { 
    LinearProgress, 
    makeStyles,
    useMediaQuery, 
    useTheme, 
} from '@material-ui/core';
import { Footer } from 'components'
import { Topbar } from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        paddingBottom: '50px',
        [theme.breakpoints.down('xs')]:{
            paddingBottom: 0,
        },
    },
    topBar: {
        zIndex: 2,
        position: 'relative'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]:{
           flexDirection: 'column',
           height: '100%',
           overflowY: 'auto',
        },
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto'
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto',
        [theme.breakpoints.down('xs')]:{
            overflowY: 'unset',
        },
    }
}));

const Dashboard = props => {
    const { route } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <div className={classes.root}>
            <Topbar className={classes.topBar} />
            <div className={classes.container}>
                <main className={classes.content}>
                    <Suspense fallback={<LinearProgress />}>
                        {renderRoutes(route.routes)}
                    </Suspense>
                    {!isMobileView && <Footer />}
                </main>
                {isMobileView && <Footer />}
            </div>
        </div>
    );
};

Dashboard.propTypes = {
    route: PropTypes.object
};

export default Dashboard;