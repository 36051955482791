import * as actionType from 'actions';

export const uploadDocumentLUT = () => ({
    type: actionType.UPLOAD_DOCUMENT_LUT,
});

export const uploadDocumentLUTSuccess = (response) => ({
    type: actionType.UPLOAD_DOCUMENT_LUT_SUCCESS,
    payload: response
});

export const uploadDocument = (document, getDocumentData) => ({
    type: actionType.UPLOAD_DOCUMENT,
    uploadDocumentPayload: document[0],
    uploadDocumentKey: document[1],
    payload: getDocumentData[0],
    key: getDocumentData[1],
});

export const uploadDocumentSuccess = (response) => ({
    type: actionType.UPLOAD_DOCUMENT_SUCCESS,
    payload: response,
});

export const uploadDocumentFailed = (response) => ({
    type: actionType.UPLOAD_DOCUMENT_FAILED,
    payload: response,
});

export const getAllDocuments = (jobApplicationGuid, key) => ({
    type: actionType.GET_DOCUMENTS,
    payload: jobApplicationGuid,
    key: key,
});

export const getAllDocumentsSuccess = (response) => ({
    type: actionType.GET_DOCUMENTS_SUCCESS,
    payload: response,
});

export const updateDocument = (updateDocument, getdocument) => ({
    type: actionType.UPDATE_DOCUMENT,
    updateDocumentPayload: updateDocument[0],
    updateDocumentKey: updateDocument[1],
    payload: getdocument[0],
    key: getdocument[1],
});

export const updateDocumentSuccess = (response) => ({
    type: actionType.UPDATE_DOCUMENT_SUCCESS,
    payload: response,
});

export const downloadDocument = (document, key) => ({
    type: actionType.DOWNLOAD_DOCUMENT,
    payload: document,
    key: key,
});

export const downloadDocumentSuccess = (response) => ({
    type: actionType.DOWNLOAD_DOCUMENT_SUCCESS,
    payload: response,
});

export const resetUpload = () => ({
    type: actionType.RESET_UPLOAD,
});

export const updateOrUploadResponseErrorClose = () => ({
    type: actionType.UPDATE_OR_UPLOAD_RESPONSE_ERROR_CLOSE
})

export const getJobApplicationSubmittedForUploadStatus = (applicationSubmittedStatus) => ({
    type: actionType.GET_APPLICATION_SUBMITTED_STATUS,
    payload: applicationSubmittedStatus
});