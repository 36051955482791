import { fieldTypeName } from 'constants/global';
import moment from 'moment';
import { Auth } from 'aws-amplify';

export const getAuthStatus = async () => {
    return Boolean(await Auth.currentUserInfo());
};


export const getJobOpeningGuid = () => {
    let jobOpeningGuid = sessionStorage.getItem("jobOpeningGuid");
    if (jobOpeningGuid) {
        return jobOpeningGuid;
    } else {
        return '';
    }
};

export const getJobOpeningUrlGuid = () => {
    let jobOpeningUrlGuid = sessionStorage.getItem("jobOpeningUrlGuid");
    if (jobOpeningUrlGuid) {
        return jobOpeningUrlGuid;
    } else {
        return '';
    }
};

export const getApplicantGuid = () => {
    let applicantGuid = sessionStorage.getItem("applicantGuid");
    if (applicantGuid) {
        return applicantGuid;
    } else {
        return '';
    }
};

export const getUserEmail = () => {
    let userEmail = sessionStorage.getItem("user_email");
    if (userEmail) {
        return userEmail;
    } else {
        return '';
    }
};




export const getJobApplicationGuid = () => {
    let applicationGuid = sessionStorage.getItem("jobApplicationGuid");
    if (applicationGuid) {
        return applicationGuid;
    } else {
        return '';
    }
};

export const getRegisteredUserAuthStatus = () => {
    let authStatus = sessionStorage.getItem("auth_status");
    return authStatus;
};

export const onLogout = () => {
    sessionStorage.removeItem("auth_status");
    sessionStorage.removeItem("applicantGuid");
    sessionStorage.removeItem("jobOpeningGuid");
    sessionStorage.removeItem("jobOpeningUrlGuid");
    sessionStorage.removeItem("jobApplicationGuid");
    sessionStorage.clear();

    window.location.href = "/";
}


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const downloadUploadedDocument = (obj) => {
    let link = document.createElement('a');
    link.href = obj.documentFile;
    link.download = obj.fileName;
    link.click();
}

export const downloadJobApplicationPdf = (obj) => {
    let link = document.createElement('a');
    link.href = "data:application/pdf;base64," + obj.jobApplicationPdf;
    link.download = obj.fileName;
    link.click();
}

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const removeSpace = (fVal) => {
    if (fVal.length > 1) {
        if (" " == fVal[fVal.length - 1]) {
            fVal = fVal.trim() + " ";
        }
        else {
            fVal = fVal.trim();
        }
    }
    else {
        fVal = fVal.trim();
    }
    return fVal;
}

const getAlphaNumericVal = (fVal) => {
    fVal = fVal.replace(/[^0-9A-Za-z]/g, "");
    return fVal;
}

const getNumericVal = (fVal) => {
    fVal = fVal.replace(/[^0-9]/g, "");
    return fVal;
}

const getNameTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/[^\-A-Za-z ]/g, "");
    return fVal;
}

const getPhoneNumTypeVal = (fVal) => {
    fVal = getNumericVal(fVal);
    return fVal;
}

const getLocationTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/[^\.A-Za-z ]/g, "");
    fVal = fVal.replace(/\.+/g, '.');
    return fVal;
}

const getLicenseNumberTypeVal = (fVal) => {
    fVal = getAlphaNumericVal(fVal);
    return fVal;
}

const getCurrentLicenseNumberTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/[^\*A-Za-z0-9 ]/g, "");
    return fVal;
}

const getZipCodeTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/[^A-Za-z0-9 ]/g, "");
    return fVal;
}
const getAddressTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\*\,\(\)\"\:\;\'\@\&\#\-\.A-Za-z0-9 ]/g, "");
    return fVal;
}

const getEmployerNameTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\_\WA-Za-z0-9 ]/g, "");
    return fVal;
}

const getPositionHeldTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/[^\,\&\-A-Za-z ]/g, "");
    return fVal;
}

const getOtherEmploymentStatusTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getUnemploymentReasonTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getOtherEquipmentClassTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getOtherNatureOfAccidentTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getOtherViolationsTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getOtherDrivingLicenseNumberTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z ]/g, "");
    return fVal;
}

const getNotesTypeVal = (fVal) => {
    fVal = removeSpace(fVal);
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/[^\,\.\(\)\:\;\'\-\&A-Za-z0-9 ]/g, "");
    return fVal;
}

const getEMailTypeVal = (fVal) => {
    fVal = fVal.replace(/\-+/g, '-');
    fVal = fVal.replace(/\.+/g, '.');
    fVal = fVal.replace(/\_+/g, '_');
    fVal = fVal.replace(/\@+/g, '@');
    fVal = fVal.replace(/[^\.\_\-\@A-Za-z0-9]/g, "");
    return fVal;
}

const getSocialSecurityNumberTypeVal = (fVal) => {
    fVal = fVal.replace(/[^\*0-9]/g, "");
  
        if (fVal !== null && fVal !== undefined && fVal !== "") {
            if (fVal.length >= 9) {
                let ssn_1 = fVal.substring(0, 9);
                let ssn_2 = ssn_1;
                let ssn_3 = ssn_1;
                fVal = ssn_1.substring(0, 3) + '-' + ssn_2.substring(3, 5) + '-' + ssn_3.substring(5);
            }
            else if (fVal.length > 5) {
                let ssn_1 = fVal.substring(0, 9);
                let ssn_2 = ssn_1;
                let ssn_3 = ssn_1;
                fVal = ssn_1.substring(0, 3) + '-' + ssn_2.substring(3, 5) + '-' + ssn_3.substring(5);

            }
            else if (fVal.length > 3) {
                let ssn_1 = fVal;
                let ssn_2 = fVal;
                fVal = ssn_1.substring(0, 3) + '-' + ssn_2.substring(3);
            }
        }
        
    return fVal;
}
export const getValForField = (event, fTypeName) => {
    switch (fTypeName) {
        case fieldTypeName.lastName:
        case fieldTypeName.firstName:
        case fieldTypeName.middleName:
            event.target.value = getNameTypeVal(event.target.value);
            break;
        case fieldTypeName.address:
            event.target.value = getAddressTypeVal(event.target.value);
            break;
        case fieldTypeName.city:
        case fieldTypeName.county:
        case fieldTypeName.state:
        case fieldTypeName.country:
            event.target.value = getLocationTypeVal(event.target.value);
            break;
        case fieldTypeName.licenseNumber:
            event.target.value = getLicenseNumberTypeVal(event.target.value);
            break;
        case fieldTypeName.currentLicenseNumber:
            event.target.value = getCurrentLicenseNumberTypeVal(event.target.value);
            break;
        case fieldTypeName.phoneNumber:
            event.target.value = getPhoneNumTypeVal(event.target.value);
            break;
        case fieldTypeName.zipCode:
            event.target.value = getZipCodeTypeVal(event.target.value);
            break;
        case fieldTypeName.employerName:
            event.target.value = getEmployerNameTypeVal(event.target.value);
            break;
        case fieldTypeName.positionHeld:
            event.target.value = getPositionHeldTypeVal(event.target.value);
            break;
        case fieldTypeName.otherEmploymentStatus:
            event.target.value = getOtherEmploymentStatusTypeVal(event.target.value);
            break;
        case fieldTypeName.unemploymentReason:
            event.target.value = getUnemploymentReasonTypeVal(event.target.value);
            break;
        case fieldTypeName.otherEquipmentClass:
            event.target.value = getOtherEquipmentClassTypeVal(event.target.value);
            break;
        case fieldTypeName.otherNatureOfAccident:
            event.target.value = getOtherNatureOfAccidentTypeVal(event.target.value);
            break;
        case fieldTypeName.otherViolations:
            event.target.value = getOtherViolationsTypeVal(event.target.value);
            break;
        case fieldTypeName.otherDrivingLicenseNumber:
            event.target.value = getOtherDrivingLicenseNumberTypeVal(event.target.value);
            break;
        case fieldTypeName.notes:
            event.target.value = getNotesTypeVal(event.target.value);
            break;
        case fieldTypeName.email:
            event.target.value = getEMailTypeVal(event.target.value);
            break;
        case fieldTypeName.socialSecurityNumber:
            event.target.value = getAlphaNumericVal(event.target.value);
            break;
        case fieldTypeName.identificationCountry:
            event.target.value = getLocationTypeVal(event.target.value);
            break;
        default: break;
    }
    return event;
};

export const validateTwoDuration = (fromMonth, fromYear, toMonth, toYear) => {
    if (fromMonth !== null && fromMonth !== undefined && fromMonth !== "" &&
        fromYear !== null && fromYear !== undefined && fromYear !== "" &&
        toMonth !== null && toMonth !== undefined && toMonth !== "" &&
        toYear !== null && toYear !== undefined && toYear !== "") {
        let fromDate = moment(fromYear + '-' + (fromMonth.length === 1 ? ('0' + fromMonth) : fromMonth) + '-' + "01", 'YYYY-MM-DD');
        let toDate = moment(toYear + '-' + (toMonth.length === 1 ? ('0' + toMonth) : toMonth) + '-' + "01", 'YYYY-MM-DD');
        return moment(toDate).isSameOrAfter(fromDate, 'day');
    }
    
    return true;
}

export const validateTwoDurationEmployementHistory = (fromMonth, fromYear, toMonth, toYear) => {
    if (fromMonth !== null && fromMonth !== undefined && fromMonth !== "" &&
        fromYear !== null && fromYear !== undefined && fromYear !== "" &&
        toMonth !== null && toMonth !== undefined && toMonth !== "" &&
        toYear !== null && toYear !== undefined && toYear !== "") {
        let fromDate = moment(fromYear + '-' + (fromMonth.length === 1 ? ('0' + fromMonth) : fromMonth) + '-' + "01", 'YYYY-MM-DD');
        let toDate = moment(toYear + '-' + (toMonth.length === 1 ? ('0' + toMonth) : toMonth) + '-' + "01", 'YYYY-MM-DD');
        return moment(toDate).isSameOrAfter(fromDate, 'YYYY-MM-DD');
    }
    
    return true;
}