import * as actionType from 'actions';

const initialState = {
    isLoading: false,
    errorMessage: {},
    errorStatus: false,
    personalInfo: {},
    employmentHistory: {},
    isJobApplicationLUTLoading: false,
    jobApplicationLUT: {},
    isExperienceLoading: false,
    experienceQualificationPayload: {},
    experienceQualification: {},
    jobApplicationGuid: "",
    isPersonalInfoLoading: false,
    personalInfoStatus: {},
    isEmploymentLoading: false,
    employmentHistoryStatus: {},
    isExperiencePosting: false,
    experienceQualificationStatus: {},
    applicantFirstName: '',
    applicantMiddleName: '',
    applicantLastName: '',
    updateApplicantFirstName: '',
    updateApplicantMiddleName: '',
    updateApplicantLastName: '',
    showDisclosure: false,
    applicantDetailsForDisclaimer: {},
    existingUser: {},
    isExistingUserLoading: false,
    screeningCriteria: {},
    isReadOnly: false,
    jobApplicationStatus : true,
    licenseClass:''
};

const jobApplication = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_PERSONAL_INFO: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionType.GET_PERSONAL_INFO_SUCCESS: {
            sessionStorage.setItem("jobApplicationGuid", action.payload?.jobApplicationGuid);
            sessionStorage.setItem("jobOpeningGuid", action.payload?.jobOpeningGuid);
            
            return {
                ...state,
                isLoading: false,
                personalInfo: action.payload?.data,
                jobApplicationGuid: action.payload?.jobApplicationGuid,
                applicantFirstName: action.payload?.data.firstName,
                applicantMiddleName: action.payload?.data.middleName,
                applicantLastName: action.payload?.data.lastName,
                showDisclosure: action.payload?.showDisclosure,
                errorStatus: (action.payload.data === null || action.payload.data === undefined) ? true : false,
                errorMessage: 'Something went wrong.',
                isReadOnly: action.payload?.isReadOnly
            };
        }

        case actionType.RESET_PERSONAL_INFO: {
            return {
                ...state,
                personalInfo: {},
                personalInfoStatus: {}
            };
        }

        case actionType.POST_PERSONAL_INFO: {
            return {
                ...state,
                isPersonalInfoLoading: true,
            };
        }

        case actionType.SAVE_PERSONAL_INFO: {
            return {
                ...state,
                isPersonalInfoLoading: true,
                updateApplicantFirstName: action.payload.firstName,
                updateApplicantMiddleName: action.payload.middleName,
                updateApplicantLastName: action.payload.lastName,
            };
        }

        case actionType.POST_PERSONAL_INFO_SUCCESS: {
            return {
                ...state,
                isPersonalInfoLoading: false,
                personalInfoStatus: action.payload,
            };
        }

        case actionType.GET_EMPLOYMENT_HISTORY: {
            return {
                ...state,
                isEmployerLoading: true,
            };
        }

        case actionType.GET_EMPLOYMENT_HISTORY_SUCCESS: {
            sessionStorage.setItem("jobApplicationGuid", action.payload.jobApplicationGuid);

            return {
                ...state,
                isEmployerLoading: false,
                employmentHistoryPayload: action.payload,
                employmentHistory: action.payload.data,
                applicantDetailsForDisclaimer: action.payload.applicantDetailsForDisclaimer
            };
        }

        case actionType.RESET_EMPLOYMENT_HISTORY: {
            return {
                ...state,
                employmentHistoryPayload: {},
                employmentHistory: {},
                employmentHistoryStatus: {},
            };
        }

        case actionType.POST_EMPLOYMENT_HISTORY: {
            return {
                ...state,
                isEmploymentLoading: true,

            };
        }

        case actionType.POST_EMPLOYMENT_HISTORY_SUCCESS: {
            return {
                ...state,
                isEmploymentLoading: false,
                employmentHistoryStatus: action.payload,
            };
        }

        case actionType.GET_JOB_APPLICATION_LUT: {
            return {
                ...state,
                isJobApplicationLUTLoading: true,
            };
        }

        case actionType.GET_JOB_APPLICATION_LUT_SUCCESS: {
            return {
                ...state,
                isJobApplicationLUTLoading: false,
                jobApplicationLUT: action.payload,
            };
        }

        case actionType.GET_EXPERIENCE_QUALIFICATION: {

            return {
                ...state,
                isExperienceLoading: true,

            };
        }

        case actionType.GET_EXPERIENCE_QUALIFICATION_SUCCESS: {
            return {
                ...state,
                isExperienceLoading: false,
                experienceQualificationPayload: action.payload,
                experienceQualification: action.payload.data,
                applicantDetailsForDisclaimer: action.payload.applicantDetailsForDisclaimer,
                applicantDetailsForDisclosureSignatureDisclaimer: action.payload.applicantDetailsForDisclosureSignatureDisclaimer,
                licenseClass: action.payload.licenseClass
            };
        }

        case actionType.RESET_EXPERIENCE_QUALIFICATIONS: {
            return {
                ...state,
                experienceQualificationPayload: {},
                experienceQualification: {},
                experienceQualificationStatus: {}
            };
        }

        case actionType.RESET_ALL_STEPS: {
            return {
                ...state,
                personalInfo: {},
                personalInfoStatus: {},
                employmentHistoryPayload: {},
                employmentHistory: {},
                employmentHistoryStatus: {},
                experienceQualificationPayload: {},
                experienceQualification: {},
                experienceQualificationStatus: {}
            };
        }

        case actionType.POST_EXPERIENCE_QUALIFICATION: {
            return {
                ...state,
                isExperiencePosting: true,
            };
        }

        case actionType.POST_EXPERIENCE_QUALIFICATION_SUCCESS: {
            return {
                ...state,
                isExperiencePosting: false,
                experienceQualificationStatus: action.payload,
            };
        }

        case actionType.UPDATE_APPLICANT_NAME: {
            return {
                ...state,
                applicantFirstName: state.updateApplicantFirstName,
                applicantMiddleName: state.updateApplicantMiddleName,
                applicantLastName: state.updateApplicantLastName,
            }
        }

        case actionType.RESET_JOB_APPLICATION: {
            sessionStorage.removeItem("auth_status");
            sessionStorage.removeItem("applicantGuid");
            sessionStorage.removeItem("jobOpeningGuid");
            sessionStorage.removeItem("jobOpeningUrlGuid");
            sessionStorage.removeItem("jobApplicationGuid");
            sessionStorage.removeItem("user_email");

            return {
                ...state,
                ...initialState
            }
        }

        case actionType.GET_EXISTING_USER: {
            return {
                ...state,
                isExistingUserLoading: true,
            };
        }

        case actionType.GET_EXISTING_USER_SUCCESS: {
            return {
                ...state,
                isExistingUserLoading: false,
                existingUser: action.payload,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isLoading: false,
                isJobApplicationLUTLoading: false,
                isExperienceLoading: false,
                isPersonalInfoLoading: false,
                isEmploymentLoading: false,
                isExperiencePosting: false,
                showDisclosure: false,
            }
        }
       
        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        case actionType.API_RETURNING_FALSE_STATUS_ERROR: {
            return {               
                 ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isPersonalInfoLoading: false,
                isEmploymentLoading: false,
                isExperienceLoading: false,
                isExperiencePosting: false,
            }
        }

        case actionType.API_RETURNING_FALSE_STATUS_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false,
                isPersonalInfoLoading: false,
                isEmploymentLoading: false,
                isExperienceLoading: false,
                isExperiencePosting: false,
            }
        }

        case actionType.GET_SCREENING_CRITERIA: {
            return {
                ...state,
                screeningCriteria: {},
            };
        }

        case actionType.GET_SCREENING_CRITERIA_SUCCESS: {
            return {
                ...state,
                screeningCriteria: action.payload
            }
        }

       case  actionType.GET_APPLICATION_SUBMITTED_STATUS:{
        return {
            ...state,
            jobApplicationStatus: action.payload
              }
       }

        default: {
            return state;
        }
    }
};

export default jobApplication;