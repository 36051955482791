import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'utils/routerConfig';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Topbar } from 'components';
import { Footer } from 'components';

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        paddingTop: 56,
        background: 'url(/images/login-bg-2.jpg) no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 26%',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '90% 26%'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
}));

const ExistingUser = props => {
    const { route } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

    const Content = () => {
       return (
        <main className={classes.content}>
            <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
            </Suspense>
        </main>
       )
    }

    return (
        <Fragment>
            <Topbar />
            { isMobileView 
                ? <div className={classes.container}>
                    <Content />
                    <Footer />
                </div>
                : <>
                    <Content />
                    <Footer />
                </>
            }
        </Fragment>
    );
};

ExistingUser.propTypes = {
    route: PropTypes.object
};

export default ExistingUser;