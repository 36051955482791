import { enableES5 } from "immer";
import produce from 'immer';
import * as actionType from 'actions';

enableES5()

// The initial state of the App
export const initialState = {
    loading: false,
    error: false,
    activeStep: 0,
    completed: {},
    loadedStates: [],
    messages: [],
    totalSteps: 0,
    stepsEnabledForClick: [],
    section: 'landingPage'
};

const wizard = (state = initialState, action) =>
    produce(state, wizard => {
        switch (action.type) {
            case actionType.NEXT_STEP:
                wizard.activeStep = action.value + 1;
                wizard.completed[action.value] = true;
                break;

            case actionType.BACK_STEP:
                wizard.activeStep = action.value - 1;
                break;

            case actionType.RESET_STEP:
                wizard.activeStep = 0;
                wizard.stepsEnabledForClick = [0];
                wizard.completed = [];
                break;

            case actionType.COMPLETED_STEPS:
                wizard.completed[action.value] = true;
                break;

            case actionType.STEPS_COUNT:
                wizard.totalSteps = action.value;
                break;

            case actionType.SELECT_STEPPER:
                wizard.activeStep = action.value + 1;
                break;

            case actionType.ENABLE_ALL_STEPPERS:
                wizard.stepsEnabledForClick = [0, 1, 2];
                wizard.completed = [1, 2, 3];
                break;

            case actionType.SET_SECTION:
                wizard.section = action.value;
                break;
        }
    });

export default wizard;