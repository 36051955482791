import * as actionType from 'actions';

export const getCountry = () => ({
    type: actionType.GET_COUNTRY
});

export const getCountrySuccess = (countires) => ({
    type: actionType.GET_COUNTRY_SUCCESS,
    payload: countires,
});

export const getState = (countryCode) => ({
    type: actionType.GET_STATE,
    payload: { countryCode },
});

export const getStateSuccess = (stateList) => ({
    type: actionType.GET_STATE_SUCCESS,
    payload: stateList,
});

export const getCounty = (stateCode) => ({
    type: actionType.GET_COUNTY,
    payload: { stateCode },
});

export const getCountySuccess = (countyList) => ({
    type: actionType.GET_COUNTY_SUCCESS,
    payload: countyList,
});

export const sendMailOnLogout = (request) => ({
    type: actionType.SEND_MAIL_ON_LOGOUT,
    payload: request,
});

export const sendMailOnLogoutSuccess = (response) => ({
    type: actionType.SEND_MAIL_ON_LOGOUT_SUCCESS,
    payload: response,
});

export const commonError = ({ error }) => ({
    type: actionType.COMMON_ERROR,
    payload: {
        error,
    }
});
export const commonErrorClose = () => ({
    type: actionType.COMMON_ERROR_CLOSE,
});

export const apiReturningFalseStatusError = ({ error }) => ({
    type: actionType.API_RETURNING_FALSE_STATUS_ERROR,
    payload: {
        error,
    }
});
export const apiReturningFalseStatusErrorClose = () => ({
    type: actionType.API_RETURNING_FALSE_STATUS_ERROR_CLOSE,
});