import { instance, instanceWithoutToken } from 'utils/axios';

export const getCountryServices = async () => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.get('api/wizard/GetCountry');
};

export const getStateServices = async countryCode => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetState', countryCode);
};

export const getCountyServices = async (stateCode) => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetCountyList', stateCode);
};

export const JobDetailsServices = async jobInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instanceWithoutToken.post('api/wizard/GetJobOpeningDetails', jobInfo);
};

export const addApplicantServices = async request => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/AddApplicant', request);
};

export const sendMailOnLogoutServices = async request => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/SendMailOnLogout', request);
};

export const addRegisteredUserServices = async request => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instanceWithoutToken.post('api/wizard/AddRegisteredUserDetail', request);
};