import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    deleteButton: {
        color: theme.palette.error.main,
        fontSize: theme.spacing(2.5),
        padding: theme.spacing(0.5,0,0.5,0),
    },
   deleteIcon: {
        fontSize: theme.spacing(3),
		marginBottom: theme.spacing(0.5)
    },
}))

const DeleteButton = ({ buttonClassName, iconClassName, onDelete }) => {
    const classes = useStyles();
    return(
        <Button
			className={clsx(classes.deleteButton, buttonClassName)}
			type="button"
			onClick={onDelete}
        >
			<DeleteIcon className={clsx(classes.deleteIcon, iconClassName)} />
			Delete
	    </Button>
    )
};

DeleteButton.propTypes = {
    buttonClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
}

export default DeleteButton;