import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from './config';

const baseURL = process.env.REACT_APP_APPLICANTMANAGEMENTAPI ? process.env.REACT_APP_APPLICANTMANAGEMENTAPI : config.applicantManagementAPI;

export const instance = axios.create({
    baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache"
    }
});

instance.interceptors.request.use(
    async config => {
        let token = await (await Auth.currentSession()).getAccessToken().getJwtToken();

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const instanceWithoutToken = axios.create({
    baseURL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache"
    }
});