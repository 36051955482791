import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';

function* getConfirmedJobApplicationDetails(request) {

    try {
        const response = yield call(services.getConfirmedJobApplicationDetailsServices, request.payload);
        yield put(actionType.getConfirmedJobApplicationDetailsSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetConfirmedJobApplicationDetailsSaga() {
    yield takeLatest(actionType.GET_CONFIRMED_JOB_APPLICATION_DETAILS, getConfirmedJobApplicationDetails);
}

const applicationConfirmation = [
    fork(watchGetConfirmedJobApplicationDetailsSaga),
];

export default applicationConfirmation;