import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'utils/routerConfig';
import PropTypes from 'prop-types';
import { 
    LinearProgress, 
    Paper,
    Typography, 
    makeStyles, 
    useMediaQuery,
    useTheme, 
} from '@material-ui/core';
import { Footer, Topbar } from 'components';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        paddingTop: 56,
        background: 'url(/images/login-bg-2.jpg) no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '50% 26%',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '90% 26%'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        },
    },
    ieCard: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        margin: theme.spacing(1),
        width: '800px',
        padding: '50px 20px'
    },
    cardTitle: {
        marginBottom: '15px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
}));

const isIE = () => {
    return /Trident\/|MSIE/.test(window.navigator.userAgent);  // IE 10 and IE 11
}

const Validate = props => {
    const { route } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

    const Content = () => {
        return(
            <main className={classes.content}> 
                <Suspense fallback={<LinearProgress />}>
                    {renderRoutes(route.routes)}
                </Suspense> 
            </main>
        )
    }

    return (
        <Fragment>
            <Topbar />

            {!isIE() 
                ? isMobileView 
                    ? <div className={classes.container}>
                        <Content />
                        <Footer />
                    </div>
                    : <Content />
                : <div className={classes.content}>
                    <Paper className={classes.ieCard} elevation={3} >
                    <Typography align='center' className={classes.cardTitle} variant="h4" component='p'>
                        Driver Applicant Center is not currently supported on Internet Explorer browser.
                        </Typography>
                    <Typography align='center' className={classes.cardTitle} variant="h4" component='p'>
                        Please use Google Chrome or Microsoft Edge browser.
                        </Typography>
                    </Paper>
                </div>
            }
            {!isMobileView && <Footer />}
        </Fragment>
    );
};

Validate.propTypes = {
    route: PropTypes.object
};

export default Validate;