import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, getIn } from 'formik';
import { TextField, FormControl, FormControlLabel, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(({
	label: {
		margin: 0,
	}
}));

const AutoComplete = ({ 
	alternativeName,
	alternativeValue,
	config, 
	fieldName,
	formControlClassName,
	isConfigRequired,
	isDisabled,
	isErrorRequired,
	label, 
	onChange, 
	options,
	value,
}) => {
	const classes = useStyles();
	const { handleBlur, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();
	const error = getIn(errors, fieldName);
	const isTouched = getIn(touched,fieldName);

	const handleChange = newValue => {
		onChange && onChange(isConfigRequired ? newValue[config?.value] : newValue)
		setFieldTouched(fieldName,true);

		if(!isConfigRequired && newValue === 'None'){
			setFieldValue(fieldName, '', true);
		}else{
			setFieldValue(
				fieldName, 
				isConfigRequired 
					? newValue[config?.value] === '' ? alternativeValue ?? '': newValue[config?.value] 
					: newValue,
				true
			);
		}
	};

	const handleOptionLabel = option => {
		const optionLabel = isConfigRequired ? option[config?.label] : option.toString();
		 
		return optionLabel === 'None' ? alternativeName ?? '' :  optionLabel;
	}

    return(
		<FormControl className={formControlClassName} disabled={isDisabled}>
			<FormControlLabel
				className={classes.label}
				control ={
					<Autocomplete
						fullWidth
						id={fieldName}
						autoComplete
						autoHighlight
						name={fieldName}
						disableClearable
						value={
							isConfigRequired 
								? options?.find(option => option[config?.value] === value) || '' 
								: value
						}
						options={[ 
							isConfigRequired 
								? { [config?.label]: 'None', [config?.value]: ''} 
								: 'None' 
							, ...options ]}
						onChange={(_,newValue) => handleChange(newValue)}
              			onBlur={handleBlur}
						getOptionLabel={option => handleOptionLabel(option)}
						renderInput={(params) => <TextField
							{...params}
							label={label}
							id={fieldName}
							variant='outlined'
							error={!!error && isTouched}
							helperText={error && isTouched && isErrorRequired ? error : ''}
						/>}
						renderOption={option => 
							<>
								{
									option?.[config?.label] === 'None' || option === 'None'
										? <em>None</em>
										: <> 
											{isConfigRequired ? option?.[config?.label] : option } 
										</>
								}
							</>
						}
          			/>}
			/>
    	</FormControl>
   )
};

AutoComplete.defaultProps = {
	isErrorRequired: true,
	isDisabled: false,
	isConfigRequired: true,
}

AutoComplete.propTypes = {
	alternativeName: PropTypes.string,
	alternativeValue: PropTypes.string,
	config: PropTypes.object,
    fieldName: PropTypes.string.isRequired,
	formControlClassName: PropTypes.string,
	isConfigRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isErrorRequired: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	value: PropTypes.number,
}

export default AutoComplete;