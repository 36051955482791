import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';
import { DecryptResponseData } from 'EncryptionDecryption/EncryptAndDecrypt';

function* deleteDisclosureSaga(deleteResponse) {
    try {
        const response = yield call(services.deleteDisclosureServices, deleteResponse.payload);
        yield put(actionType.deleteDisclosureSuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchDeleteDisclosureSaga() {
    yield takeLatest(actionType.DELETE_DISCLOSURE_DOCUMENT, deleteDisclosureSaga);
}

function* getDisclosureSaga(getResponse) {
    try {
        const response = yield call(services.getDisclosureServices, getResponse);

        response.data =  DecryptResponseData(response.data, "getDisclosureDocument");

        yield put(actionType.getDisclosureSuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetDisclosureSaga() {
    yield takeLatest(actionType.GET_DISCLOSURE_DOCUMENT, getDisclosureSaga);
}

function* postDisclosureSaga(postResponse) {
    try {
        const response = yield call(services.postDisclosureServices, postResponse);

        response.data =  DecryptResponseData(response.data, "postDisclosure");

        yield put(actionType.postDisclosureSuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchPostDisclosureSaga() {
    yield takeLatest(actionType.POST_DISCLOSURE_DOCUMENT, postDisclosureSaga);
}

function* getDisclosureTypesSaga(getResponse) {
    try {
        const response = yield call(services.getDisclosureTypesServices, getResponse.payload);
        yield put(actionType.getDisclosureTypesSuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetDisclosureTypesSaga() {
    yield takeLatest(actionType.GET_DISCLOSURE_TYPES, getDisclosureTypesSaga);
}

function* getMvrDocumentsSaga(getResponse) {
    try {
        const response = yield call(services.getMvrDocumentsServices, getResponse);

        response.data =  DecryptResponseData(response.data, "getMvrDocuments");

        yield put(actionType.getMvrDocumentsSuccess(response.data))
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetMvrDocumentsSaga() {
    yield takeLatest(actionType.GET_MVR_DOCUMENTS, getMvrDocumentsSaga);
}

function* updateDisclosureSaga(disclosure) {
    try {
        const response = yield call(services.updateDisclosureServices, disclosure);
        response.data =  DecryptResponseData(response.data, "updateDisclosure");

        yield put(actionType.updateDisclosureSuccess(response.data));

        if (response.data.status == true) {
            const response = yield call(services.getAllDocumentsServices, disclosure);
            response.data =  DecryptResponseData(response.data, "getAllDocuments");

            yield put(actionType.getAllDocumentsSuccess(response.data));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUpdateDocumentSaga() {
    yield takeLatest(actionType.UPDATE_DISCLOSURE, updateDisclosureSaga);
}

const disclosures = [
    fork(watchDeleteDisclosureSaga),
    fork(watchGetDisclosureSaga),
    fork(watchPostDisclosureSaga),
    fork(watchGetDisclosureTypesSaga),
    fork(watchGetMvrDocumentsSaga),
    fork(watchUpdateDocumentSaga)
];

export default disclosures;