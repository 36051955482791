import * as actionType from 'actions';

const initialState = {
    errorMessage: {},
    errorStatus: false,
    stateListArr: [],
    isDeleteLoading: false,
    deleteDisclousreResponse: {},
    isDisclosureUpdating: false,
    isGetDisclosureLoading: false,
    isDisclosureUploading: false,
    uploadResponse: {},
    getDisclosuresPayload: {},
    isPostDisclosureLoading: false,
    postDisclosuresPayload: {},
    isLastDocument: false,
    isDisclosureTypesLoading: false,
    disclosureTypes: {},
    applicantDetailsForDisclosureSignatureDisclaimer: {},
    isMvrDocumentsLoading: false,
    mvrDocuments: {}
};

const disclosures = (state = initialState, action) => {
    switch (action.type) {
        case actionType.DELETE_DISCLOSURE_DOCUMENT: {
            return {
                ...state,
                isDeleteLoading: true,
            };
        }

        case actionType.DELETE_DISCLOSURE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isDeleteLoading: false,
                deleteDisclousreResponse: action.payload,
            };
        }

        case actionType.GET_DISCLOSURE_DOCUMENT: {
            return {
                ...state,
                isGetDisclosureLoading: true,

            };
        }

        case actionType.GET_DISCLOSURE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isGetDisclosureLoading: false,
                getDisclosuresPayload: action.payload.disclosure,
                isLastDocument: action.payload.isLastDocument,
                applicantDetailsForDisclosureSignatureDisclaimer: action.payload.applicantDetailsForDisclosureSignatureDisclaimer
            };
        }

        case actionType.POST_DISCLOSURE_DOCUMENT: {
            return {
                ...state,
                isPostDisclosureLoading: true,

            };
        }

        case actionType.POST_DISCLOSURE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isPostDisclosureLoading: false,
                postDisclosuresPayload: action.payload
            };
        }

        case actionType.GET_DISCLOSURE_TYPES: {
            return {
                ...state,
                isDisclosureTypesLoading: true,
            };
        }

        case actionType.GET_DISCLOSURE_TYPES_SUCCESS: {
            
            let orderedDisclosureTypes = {};
            let sortOrder = [1, 2, 5, 3];

            for (var i = 0; i < sortOrder.length; i++) {
                orderedDisclosureTypes[sortOrder[i]] = i;
            }
            
            action.payload.disclosureTypes && action.payload.disclosureTypes.sort(function (a, b) {
                return (orderedDisclosureTypes[a.id] - orderedDisclosureTypes[b.id])
            });

            return {
                ...state,
                isDisclosureTypesLoading: false,
                disclosureTypes: action.payload.disclosureTypes,
            };
        }

        case actionType.GET_MVR_DOCUMENTS: {
            return {
                ...state,
                isMvrDocumentsLoading: true
            }
        }

        case actionType.GET_MVR_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isMvrDocumentsLoading: false,
                mvrDocuments: action.payload
            }
        }

        case actionType.UPLOAD_DISCLOSURE: {
            return {
                ...state,
                isDisclosureUploading: true,
                uploadResponse: {}
            }
        }

        case actionType.UPLOAD_DISCLOSURE_SUCCESS: {
            return {
                ...state,
                isDisclosureUploading: false,
                uploadResponse: action.payload
            }
        }

        case actionType.UPDATE_DISCLOSURE: {
            return {
                ...state,
                isDisclosureUpdating: true,
                updateResponse: {}
            }
        }

        case actionType.UPDATE_DISCLOSURE_SUCCESS: {
            return {
                ...state,
                isDisclosureUpdating: false,
                updateResponse: action.payload
            }
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isDeleteLoading: false,
                isGetDisclosureLoading: false,
                isPostDisclosureLoading: false,
                isLastDocument: false,
                isDisclosureTypesLoading: false,
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        default: {
            return state;
        }
    }
};

export default disclosures;