// Get Initial Job Details
export const GET_JOB_DETAILS = 'GET_JOB_DETAILS';
export const GET_JOB_DETAILS_SUCCESS = 'GET_JOB_DETAILS_SUCCESS';

export const UPDATE_JOB_APPLICATION_STATUS = 'UPDATE_JOB_APPLICATION_STATUS';
export const UPDATE_JOB_APPLICATION_STATUS_SUCCESS = 'UPDATE_JOB_APPLICATION_STATUS_SUCCESS';

export const RESET_JOB_APPLICATION = 'RESET_JOB_APPLICATION';

// Authentication
export const GET_JOB_DETAILS_LOCAL = 'GET_JOB_DETAILS_LOCAL';

export const GET_EXISTING_USER = 'GET_EXISTING_USER';
export const GET_EXISTING_USER_SUCCESS = 'GET_EXISTING_USER_SUCCESS';

// Wizard
export const TAB_CHANGE = 'TAB_CHANGE';
export const NEXT_STEP = 'NEXT_STEP';
export const BACK_STEP = 'BACK_STEP';
export const COMPLETED_STEPS = 'COMPLETED_STEPS';
export const RESET_STEP = 'RESET_STEP';
export const STEPS_COUNT = 'STEPS_COUNT';
export const SELECT_STEPPER = 'SELECT_STEPPER';
export const ENABLE_ALL_STEPPERS = 'ENABLE_ALL_STEPPERS';
export const SET_SECTION = 'SET_SECTION';

export const AUTH_LOGIN_POST = 'AUTH_LOGIN_POST';
export const AUTH_LOGIN_POST_SUCCESS = 'AUTH_LOGIN_POST_SUCCESS';

export const AUTH_REGISTER_POST = 'AUTH_REGISTER_POST';
export const AUTH_REGISTER_POST_SUCCESS = 'AUTH_REGISTER_POST_SUCCESS';

export const AUTH_FORGOT_PASSWORD_POST = 'AUTH_FORGOT_PASSWORD_POST';
export const AUTH_FORGOT_PASSWORD_POST_SUCCESS = 'AUTH_FORGOT_PASSWORD_POST_SUCCESS';

export const AUTH_FORGOT_PASSWORD_VALIDATION = 'AUTH_FORGOT_PASSWORD_VALIDATION';
export const AUTH_FORGOT_PASSWORD_VALIDATION_SUCCESS = 'AUTH_FORGOT_PASSWORD_VALIDATION_SUCCESS';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';

export const AUTH_LOGOUT_POST = 'AUTH_LOGOUT_POST';
export const AUTH_LOGOUT_POST_SUCCESS = 'AUTH_LOGOUT_POST_SUCCESS';

export const AUTH_RESEND_SIGN_UP_POST = 'AUTH_RESEND_SIGN_UP_POST';
export const AUTH_RESEND_SIGN_UP_SUCCESS = 'AUTH_RESEND_SIGN_UP_SUCCESS';
export const AUTH_RESEND_SIGN_UP_FAILED = 'AUTH_RESEND_SIGN_UP_FAILED';

export const ADD_APPLICANT = 'ADD_APPLICANT';
export const ADD_APPLICANT_SUCCESS = 'ADD_APPLICANT_SUCCESS'

export const RESET_ALL_STEPS = 'RESET_ALL_STEPS';
export const RESET_PERSONAL_INFO = 'RESET_PERSONAL_INFO';
export const RESET_EMPLOYMENT_HISTORY = 'RESET_EMPLOYMENT_HISTORY';
export const RESET_EXPERIENCE_QUALIFICATIONS = 'RESET_EXPERIENCE_QUALIFICATIONS';

// Personal Info
export const GET_PERSONAL_INFO = 'GET_PERSONAL_INFO';
export const GET_PERSONAL_INFO_SUCCESS = 'GET_PERSONAL_INFO_SUCCESS';

export const POST_PERSONAL_INFO = 'POST_PERSONAL_INFO';
export const POST_PERSONAL_INFO_SUCCESS = 'POST_PERSONAL_INFO_SUCCESS';

export const SAVE_PERSONAL_INFO = 'SAVE_PERSONAL_INFO';
// Employment History
export const GET_EMPLOYMENT_HISTORY = 'GET_EMPLOYMENT_HISTORY';
export const GET_EMPLOYMENT_HISTORY_SUCCESS = 'GET_EMPLOYMENT_HISTORY_SUCCESS';

export const POST_EMPLOYMENT_HISTORY = 'POST_EMPLOYMENT_HISTORY';
export const POST_EMPLOYMENT_HISTORY_SUCCESS = 'POST_EMPLOYMENT_HISTORY_SUCCESS';

// Experience and Qualifications
export const GET_EXPERIENCE_QUALIFICATION = 'GET_EXPERIENCE_QUALIFICATION';
export const GET_EXPERIENCE_QUALIFICATION_SUCCESS = 'GET_EXPERIENCE_QUALIFICATION_SUCCESS';

export const POST_EXPERIENCE_QUALIFICATION = 'POST_EXPERIENCE_QUALIFICATION';
export const POST_EXPERIENCE_QUALIFICATION_SUCCESS = 'POST_EXPERIENCE_QUALIFICATION_SUCCESS';


export const GET_JOB_APPLICATION_LUT = 'GET_JOB_APPLICATION_LUT';
export const GET_JOB_APPLICATION_LUT_SUCCESS = 'GET_JOB_APPLICATION_LUT_SUCCESS';

// Review
export const GET_REVIEW_INFO = 'GET_REVIEW_INFO';
export const GET_REVIEW_INFO_SUCCESS = 'GET_REVIEW_INFO_SUCCESS';

export const POST_REVIEW_INFO = 'POST_REVIEW_INFO';
export const POST_REVIEW_INFO_SUCCESS = 'POST_REVIEW_INFO_SUCCESS';

export const RESET_UPLOAD = 'RESET_UPLOAD';

export const UPLOAD_DOCUMENT_LUT = 'UPLOAD_DOCUMENT_LUT';
export const UPLOAD_DOCUMENT_LUT_SUCCESS = 'UPLOAD_DOCUMENT_LUT_SUCCESS';

export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT_FAILED';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';

export const UPDATE_OR_UPLOAD_RESPONSE_ERROR_CLOSE = 'UPDATE_OR_UPLOAD_RESPONSE_ERROR_CLOSE';

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';

// Disclosures
export const DELETE_DISCLOSURE_DOCUMENT = 'DELETE_DISCLOSURE_DOCUMENT';
export const DELETE_DISCLOSURE_DOCUMENT_SUCCESS = 'DELETE_DISCLOSURE_DOCUMENT_SUCCESS';

export const GET_DISCLOSURE_DOCUMENT = 'GET_DISCLOSURE_DOCUMENT';
export const GET_DISCLOSURE_DOCUMENT_SUCCESS = 'GET_DISCLOSURE_DOCUMENT_SUCCESS';

export const POST_DISCLOSURE_DOCUMENT = 'POST_DISCLOSURE_DOCUMENT';
export const POST_DISCLOSURE_DOCUMENT_SUCCESS = 'POST_DISCLOSURE_DOCUMENT_SUCCESS';

export const GET_DISCLOSURE_TYPES = 'GET_DISCLOSURE_TYPES';
export const GET_DISCLOSURE_TYPES_SUCCESS = 'GET_DISCLOSURE_TYPES_SUCCESS';

export const GET_MVR_DOCUMENTS = 'GET_MVR_DOCUMENTS';
export const GET_MVR_DOCUMENTS_SUCCESS = 'GET_MVR_DOCUMENTS_SUCCESS'

export const UPLOAD_DISCLOSURE = 'UPLOAD_DISCLOSURE'
export const UPLOAD_DISCLOSURE_SUCCESS = 'UPLOAD_DISCLOSURE_SUCCESS'

export const UPDATE_DISCLOSURE = 'UPDATE_DISCLOSURE'
export const UPDATE_DISCLOSURE_SUCCESS = 'UPDATE_DISCLOSURE_SUCCESS'

// Application Confimation
export const GET_CONFIRMED_JOB_APPLICATION_DETAILS = 'GET_CONFIRMED_JOB_APPLICATION_DETAILS';
export const GET_CONFIRMED_JOB_APPLICATION_DETAILS_SUCCESS = 'GET_CONFIRMED_JOB_APPLICATION_DETAILS_SUCCESS';

// Get Job Application PDF
export const GET_JOB_APPLICATION_PDF = 'GET_JOB_APPLICATION_PDF';
export const GET_JOB_APPLICATION_PDF_SUCCESS = 'GET_JOB_APPLICATION_PDF_SUCCESS';

// Update Applicant Name
export const UPDATE_APPLICANT_NAME = 'UPDATE_APPLICANT_NAME';

// Common error dispatcher
export const COMMON_ERROR = 'COMMON_ERROR';
export const COMMON_ERROR_CLOSE = 'COMMON_ERROR_CLOSE';


// Api error dispatcher
export const API_RETURNING_FALSE_STATUS_ERROR = 'API_RETURNING_FALSE_STATUS_ERROR';
export const API_RETURNING_FALSE_STATUS_ERROR_CLOSE = 'API_RETURNING_FALSE_STATUS_ERROR_CLOSE';

//For getting country, state and county
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';

export const GET_STATE = 'GET_STATE';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';

export const GET_COUNTY = 'GET_COUNTY';
export const GET_COUNTY_SUCCESS = 'GET_COUNTY_SUCCESS';

export const SEND_MAIL_ON_LOGOUT = 'SEND_MAIL_ON_LOGOUT';
export const SEND_MAIL_ON_LOGOUT_SUCCESS = 'SEND_MAIL_ON_LOGOUT_SUCCESS';

export const GET_SCREENING_CRITERIA = 'GET_SCREENING_CRITERIA';
export const GET_SCREENING_CRITERIA_SUCCESS = 'GET_SCREENING_CRITERIA_SUCCESS';

export const ADD_REGISTERED_USER = 'ADD_REGISTERED_USER';
export const ADD_REGISTERED_USER_SUCCESS = 'ADD_REGISTERED_USER_SUCCESS';

export const GET_HEADERDETAILS_SUCCESS = 'GET_HEADERDETAILS_SUCCESS';

export const GET_APPLICATION_SUBMITTED_STATUS = 'GET_APPLICATION_SUBMITTED_STATUS';

export const RESET_RESEND_SIGN_UP = 'RESET_RESEND_SIGN_UP';

export const RESET_USER_ALREADY_EXISTS = 'RESET_USER_ALREADY_EXISTS';