import * as actionType from 'actions';

const initialState = {
    isReviewInfoLoading: false,
    isReviewInfoPosting: false,
    reviewDetails: {},
    reviewSubmitResponse: {},
    errorStatus: false,
    errorMessage: ''
};

const review = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_REVIEW_INFO: {
            return {
                ...state,
                isReviewInfoLoading: true,
            };
        }

        case actionType.GET_REVIEW_INFO_SUCCESS: {
            return {
                ...state,
                isReviewInfoLoading: false,
                reviewDetails: action.payload,
            };
        }

        case actionType.POST_REVIEW_INFO: {
            return {
                ...state,
                isReviewInfoPosting: true,
            };
        }

        case actionType.POST_REVIEW_INFO_SUCCESS: {
            return {
                ...state,
                isReviewInfoPosting: false,
                reviewSubmitResponse: action.payload,
            };
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isReviewInfoLoading: false,
                isReviewInfoPosting: false,
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        default: {
            return state;
        }
    }
};

export default review;