import * as actionType from 'actions';

export const getConfirmedJobApplicationDetails = (request) => ({
    type: actionType.GET_CONFIRMED_JOB_APPLICATION_DETAILS,
    payload: request,
});

export const getConfirmedJobApplicationDetailsSuccess = (response) => ({
    type: actionType.GET_CONFIRMED_JOB_APPLICATION_DETAILS_SUCCESS,
    payload: response,
}); 