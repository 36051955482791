import { instance } from "utils/axios";

export const uploadDocumentLUTServices = async () => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.get('api/wizard/GetAllDocumentTypes');
};

export const uploadDocumentServices = async docInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = docInfo.uploadDocumentKey;
    return await instance.post('api/wizard/UploadJobApplicationDocuments', docInfo.uploadDocumentPayload);
};

export const getAllDocumentsServices = async document => {
    instance.defaults.headers.post['JJKAuthTF'] = document.key;
    return await instance.post('api/wizard/GetJobApplicationDocuments', document.payload);
};

export const updateDocumentServices = async personalInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = personalInfo.updateDocumentKey;
    return await instance.post('api/wizard/UpdateJobApplicationDocument', personalInfo.updateDocumentPayload);
};

export const downloadDocumentServices = async document => {
    instance.defaults.headers.post['JJKAuthTF'] = document.key;
    return await instance.post('api/wizard/DownloadJobApplicationDocument', document.payload);
};

export const uploadDlDocumentServices = async dlData => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/Wizard/UploadJobApplicationDriverLicense', dlData);
};

export const deleteDocumentServices = async data =>{
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/DeleteDriverLicense', data);
}