import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';
import { DecryptResponseData } from 'EncryptionDecryption/EncryptAndDecrypt';

function* getPersonalInfoSaga(jobInfo) {
    try {
        const response = yield call(services.getPersonalInfoServices, jobInfo);
        response.data = DecryptResponseData(response.data, "getPersonalInfo"); 

        yield put(actionType.getPersonalInfoSuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetPersonalInfoSaga() {
    yield takeLatest(actionType.GET_PERSONAL_INFO, getPersonalInfoSaga);
}

function* postPersonalInfoSaga(jobInfo) {
    try {
        const response = yield call(services.postPersonalInfoServices, jobInfo);

        response.data = DecryptResponseData(response.data, "postPersonalInfo"); 

        if(response.data.status)
        {
            yield put(actionType.postPersonalInfoSuccess(response.data));
        }
        else if(response.data.status === false
            && (response.data.message === "Application submitted" 
            || response.data.message === "Application has been already submitted" ))
        {
            yield put(actionType.getJobApplicationSubmittedStatus(response.data.status));
        }
        else
        {
            yield put(actionType.apiReturningFalseStatusError({
              error: 'Something went wrong'
             }));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchPostPersonalInfoSaga() {
    yield takeLatest(actionType.POST_PERSONAL_INFO, postPersonalInfoSaga);
}

function* getEmploymentHistorySaga(employmentInfo) {
    try {
        const response = yield call(services.getEmploymentHistoryServices, employmentInfo);

        response.data =  DecryptResponseData(response.data, "getEmploymentHistory"); 
        
        yield put(actionType.getEmploymentHistorySuccess(response.data));

    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetEmploymentHistorySaga() {
    yield takeLatest(actionType.GET_EMPLOYMENT_HISTORY, getEmploymentHistorySaga);
}

function* postEmploymentHistorySaga(employmentInfo) {
    try {
        const response = yield call(services.postEmploymentHistoryServices, employmentInfo);

        response.data = DecryptResponseData(response.data, "postEmploymentHistory"); 

        if(response.data.status)
        {
            yield put(actionType.postEmploymentHistorySuccess(response.data));
        }
        else if(response.data.status === false
            && (response.data.message === "Application submitted" 
            || response.data.message === "Application has been already submitted" ))
        {
            yield put(actionType.getJobApplicationSubmittedStatus(response.data.status));
        }
        else
        {
            yield put(actionType.apiReturningFalseStatusError({
                error: 'Something went wrong'
            }));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchPostEmploymentHistorySaga() {
    yield takeLatest(actionType.POST_EMPLOYMENT_HISTORY, postEmploymentHistorySaga);
}

function* getJobApplicationLUTSaga(jobApplicationLUTRequest) {
    try {
        const response = yield call(services.getJobApplicationLUTServices, jobApplicationLUTRequest.payload);
        yield put(actionType.getJobApplicationLUTSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetJobApplicationLUTSaga() {
    yield takeLatest(actionType.GET_JOB_APPLICATION_LUT, getJobApplicationLUTSaga);
}

function* getExperienceQualificationSaga(expInfo) {
    try {
        const lutResponse = yield call(services.getJobApplicationLUTServices, expInfo.lut);
        yield put(actionType.getJobApplicationLUTSuccess(lutResponse.data));

        if (lutResponse.data) {
            const response = yield call(services.getExperienceQualificationServices, expInfo);

            response.data = DecryptResponseData(response.data, "getExperienceQualification");

            yield put(actionType.getExperienceQualificationSuccess(response.data));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetExperienceQualificationSaga() {
    yield takeLatest(actionType.GET_EXPERIENCE_QUALIFICATION, getExperienceQualificationSaga);
}

function* postExperienceQualificationSaga(expInfo) {
    try {
        const response = yield call(services.postExperienceQualificationServices, expInfo);
        response.data = DecryptResponseData(response.data, "postExperienceQualification");

        if(response.data.status)
        {
            yield put(actionType.postExperienceQualificationSuccess(response.data));
        }
        else if(response.data.status === false
            && (response.data.message === "Application submitted" 
            || response.data.message === "Application has been already submitted" ))
        {
            yield put(actionType.getJobApplicationSubmittedStatus(response.data.status));
        }
        else
        {
            yield put(actionType.apiReturningFalseStatusError({
                error: 'Something went wrong'
            }));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchPostExperienceQualificationSaga() {
    yield takeLatest(actionType.POST_EXPERIENCE_QUALIFICATION, postExperienceQualificationSaga);
}

function* getScreeningCriteriaSaga(jobInfo) {
    try {
        const response = yield call(services.getScreeinigCriteriaServices, jobInfo.payload);
        yield put(actionType.getScreeningCriteriaSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetScreeningCriteriaSaga() {
    yield takeLatest(actionType.GET_SCREENING_CRITERIA, getScreeningCriteriaSaga);
}

function* updateJobApplicationStatusSaga(expInfo) {
    try {
        const response = yield call(services.updateJobApplicationStatusServices, expInfo.payload);

        if (response.status) {
            yield put(actionType.logout());
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUpdateJobApplicationStatusSaga() {
    yield takeLatest(actionType.UPDATE_JOB_APPLICATION_STATUS, updateJobApplicationStatusSaga);
}

const jobApplication = [
    fork(watchGetPersonalInfoSaga),
    fork(watchPostPersonalInfoSaga),
    fork(watchGetEmploymentHistorySaga),
    fork(watchPostEmploymentHistorySaga),
    fork(watchGetJobApplicationLUTSaga),
    fork(watchGetExperienceQualificationSaga),
    fork(watchPostExperienceQualificationSaga),
    fork(watchGetScreeningCriteriaSaga),
    fork(watchUpdateJobApplicationStatusSaga),
];

export default jobApplication;