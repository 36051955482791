import * as actionType from 'actions';

export const loginAuthPost = loginInfo => ({
	type: actionType.AUTH_LOGIN_POST,
	payload: loginInfo
});

export const loginAuthPostSuccess = loginStatus => ({
	type: actionType.AUTH_LOGIN_POST_SUCCESS,
	payload: loginStatus
});

export const logout = () => ({
	type: actionType.AUTH_LOGOUT_POST
});


export const logoutSuccess = () => ({
	type: actionType.AUTH_LOGOUT_POST_SUCCESS
});

export const registerAuthPost = registerInfo => ({
	type: actionType.AUTH_REGISTER_POST,
	payload: registerInfo
});

export const registerAuthPostSuccess = registerStatus => ({
	type: actionType.AUTH_REGISTER_POST_SUCCESS,
	payload: registerStatus
});

export const forgotPasswordAuthPost = forgotPassInfo => ({
	type: actionType.AUTH_FORGOT_PASSWORD_POST,
	payload: forgotPassInfo
});

export const forgotPasswordAuthPostSuccess = registerStatus => ({
	type: actionType.AUTH_FORGOT_PASSWORD_POST_SUCCESS,
	payload: registerStatus
});

export const forgotPasswordValidation = userInfo => ({
	type: actionType.AUTH_FORGOT_PASSWORD_VALIDATION,
	payload: userInfo
})

export const forgotPasswordValidationSuccess = result => ({
	type: actionType.AUTH_FORGOT_PASSWORD_VALIDATION_SUCCESS,
	payload: result
})

export const addApplicant = request => ({
	type: actionType.ADD_APPLICANT,
	payload: request
});

export const addApplicantSuccess = response => ({
	type: actionType.ADD_APPLICANT_SUCCESS,
	payload: response
});

export const addRegisteredUser = request => ({
	type: actionType.ADD_REGISTERED_USER,
	payload: request
});

export const addRegisteredUserSuccess = response => ({
	type: actionType.ADD_REGISTERED_USER_SUCCESS,
	payload: response
});

export const GetHeaderDetails = (headers)=>({
    type: actionType.GET_HEADERDETAILS_SUCCESS,
    payload: headers,
})

export const resendSignUpAuthPost = resendInfo => ({
	type: actionType.AUTH_RESEND_SIGN_UP_POST,
	payload: resendInfo
});

export const resendSignUpAuthPostSuccess = resendStatus => ({
	type: actionType.AUTH_RESEND_SIGN_UP_SUCCESS,
	payload: resendStatus
});

export const resendSignUpAuthPostFailed = resendStatus => ({
	type: actionType.AUTH_RESEND_SIGN_UP_FAILED,
	payload: resendStatus
});

export const resetResendSignUp = () => ({
    type: actionType.RESET_RESEND_SIGN_UP,
});

export const resetUserAlreadyExists = () => ({
	type: actionType.RESET_USER_ALREADY_EXISTS
});