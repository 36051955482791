import { colors } from '@material-ui/core';

export default {
    root: {
        borderRadius: 3,
        overflow: 'hidden'
    },
    colorPrimary: {
        backgroundColor: colors.blueGrey[50]
    }
};