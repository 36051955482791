import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';
import { downloadJobApplicationPdf } from 'utils/settings';
import { DecryptResponseData } from 'EncryptionDecryption/EncryptAndDecrypt';

function* getJobApplicationPdfSaga(request) {
    try {
        const response = yield call(services.getJobApplicationPdfServices, request);
        response.data = DecryptResponseData(response.data, "getJobApplicationPdf"); 

        if (response.data.status) {
            downloadJobApplicationPdf(response.data);
        }
        yield put(actionType.getJobApplicationPdfSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetJobApplicationPdfSaga() {
    yield takeLatest(actionType.GET_JOB_APPLICATION_PDF, getJobApplicationPdfSaga);
}

const jobApplicationPdf = [
    fork(watchGetJobApplicationPdfSaga),
];

export default jobApplicationPdf;