import { instance } from "utils/axios";

export const getReviewInfoServices = async reviewInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = reviewInfo.key;
    return await instance.post('api/wizard/GetJobApplicationDetails', reviewInfo.payload);
};

export const postReviewInfoServices = async reviewInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = reviewInfo.key;
    return await instance.post('api/wizard/SubmitJobApplication', reviewInfo.payload);
};

export const getJobApplicationPdf = async request => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetJobApplicationPdf', request);
};