import * as actionType from 'actions';

export const deleteDisclosure = (jobApplicationGuid) => ({
    type: actionType.DELETE_DISCLOSURE_DOCUMENT,
    payload: jobApplicationGuid
});

export const deleteDisclosureSuccess = (response) => ({
    type: actionType.DELETE_DISCLOSURE_DOCUMENT_SUCCESS,
    payload: response,
});

export const getDisclosureDocument = (guidInfo, key) => ({
    type: actionType.GET_DISCLOSURE_DOCUMENT,
    payload: guidInfo,
    key: key,
});

export const getDisclosureSuccess = (response) => ({
    type: actionType.GET_DISCLOSURE_DOCUMENT_SUCCESS,
    payload: response,
});

export const postDisclosure = (disclosureData, key) => ({
    type: actionType.POST_DISCLOSURE_DOCUMENT,
    payload: disclosureData,
    key: key,
});

export const postDisclosureSuccess = (response) => ({
    type: actionType.POST_DISCLOSURE_DOCUMENT_SUCCESS,
    payload: response,
});

export const getDisclosureTypes = (request) => ({
    type: actionType.GET_DISCLOSURE_TYPES,
    payload: request
});

export const getDisclosureTypesSuccess = (response) => ({
    type: actionType.GET_DISCLOSURE_TYPES_SUCCESS,
    payload: response,
});

export const getMvrDocuments = (request, key) => ({
    type: actionType.GET_MVR_DOCUMENTS,
    payload: request,
    key: key,
});

export const getMvrDocumentsSuccess = (response) => ({
    type: actionType.GET_MVR_DOCUMENTS_SUCCESS,
    payload: response
});

export const uploadDisclosure = (request, getDocument) => ({
    type: actionType.UPLOAD_DISCLOSURE,
    uploadDisclosurePayload: request[0],
    uploadDisclosureKey: request[1],
    payload: getDocument[0],
    key: getDocument[1],
})

export const uploadDisclosureSuccess = (response) => ({
    type: actionType.UPLOAD_DISCLOSURE_SUCCESS,
    payload: response
})

export const updateDisclosure = (updateDisclosure, getDocument) => ({
    type: actionType.UPDATE_DISCLOSURE,
    updateDisclosurePayload: updateDisclosure[0],
    updateDisclosureKey: updateDisclosure[1],
    payload: getDocument[0],
    key: getDocument[1],
});

export const updateDisclosureSuccess = (response) => ({
    type: actionType.UPDATE_DISCLOSURE_SUCCESS,
    payload: response,
});