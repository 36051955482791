import auth from './auth';
import jobApplication from './jobApplication';
import common from './common';
import review from './review';
import uploadDocument from './uploadDocument';
import applicationConfirmation from './applicationConfirmation';
import jobApplicationPdf from './jobApplicationPdf';
import disclosures from './disclosure';
import { all } from 'redux-saga/effects';

function* rootSaga() {
	yield all([
		...auth,
		...jobApplication,
		...common,
		...review,
		...uploadDocument,
		...applicationConfirmation,
		...jobApplicationPdf,
		...disclosures
	]);
}

export default rootSaga;