import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    applicantCenterTitle: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
     },
    companyName: {
        color: theme.palette.white,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'column',
        }
    },
    heading: {
        color: theme.palette.white,
        fontWeight: '400',
        lineHeight: 'normal',
    },
    headerWrapper: {
        position: 'relative'
    },
    imgContainter: {
        display: 'block',
    },
    logoImg: {
        maxHeight: '80px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '130px',
        }
    },
    root: {
        boxShadow: 'none',
        backgroundColor: theme.palette.darkBlue
    },
    title: {
        width: '100%',
        textAlign: 'center',
    },
}));

const Topbar = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    const jobDetails = useSelector(state => state.auth.jobDetails);

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <Toolbar className={classes.headerWrapper}>
                { (jobDetails.logoInBase64Format || jobDetails.companyName)
                    ? <div className={classes.container}>
                        { jobDetails?.logoInBase64Format 
                        ? <div className={classes.imgContainter}>
                            <img 
                                alt="Logo" 
                                src={ jobDetails?.logoInBase64Format?.startsWith("data:image") 
                                        ? jobDetails?.logoInBase64Format 
                                        : 'data:image/jpeg;base64,' + jobDetails?.logoInBase64Format
                                } 
                                className={classes.logoImg} 
                                />
                        </div>
                        : <Typography className={classes.companyName} variant="h3">
                            {jobDetails.companyName}
                        </Typography> }
                        <Typography className={clsx(classes.heading, classes.applicantCenterTitle)} variant="h3">
                            | Applicant Center
                        </Typography>
                        </div>
                    : <Typography className={clsx(classes.heading, classes.title)} variant="h3">
                        Applicant Center
                    </Typography>
                }
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string
};

export default Topbar;