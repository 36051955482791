import * as actionType from 'actions';

const initialState = {
    errorMessage: {},
    errorStatus: false,
    isGetDocLoading: false,
    jobApplicationDocuments: [],
    jobApplicationDisclosures: [],
    isUpdateDocLoading: false,
    updateResponse: {},
    isLUTLoading: false,
    docTypeLUT: [],
    isUpLoading: false,
    uploadResponse: {},
    isDownloadDocumentLoading: false,
    downloadResponse: {},
    canApplicantProceed: true,
    isDLAndMECMandatory: false,
    documentValidationMessages: [],
    jobApplicationStatus : true,
    isDocDeleted: false
};

const uploadDocument = (state = initialState, action) => {
    switch (action.type) {

        case actionType.UPLOAD_DOCUMENT_LUT: {
            return {
                ...state,
                isLUTLoading: true,

            };
        }

        case actionType.UPLOAD_DOCUMENT_LUT_SUCCESS: {
            return {
                ...state,
                isLUTLoading: false,
                docTypeLUT: action.payload.documentList,
            };
        }

        case actionType.UPLOAD_DOCUMENT: {
            return {
                ...state,
                isUpLoading: true,
                documentValidationMessages: []
            };
        }

        case actionType.UPLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isUpLoading: false,
                uploadResponse: action.payload,
                documentValidationMessages: action.payload.displayMessages 
                    ? Object.entries(action.payload.displayMessages) 
                    : []
            };
        }

        case actionType.UPLOAD_DOCUMENT_FAILED: {
            return {
                ...state,
                documentValidationMessages: action.payload.displayMessages 
                    ? Object.entries(action.payload.displayMessages)
                    : []
            }
        }

        case actionType.RESET_UPLOAD: {
            return {
                ...state,
                uploadResponse: {},
                documentValidationMessages: [],
            };
        }

        case actionType.GET_DOCUMENTS: {
            return {
                ...state,
                isGetDocLoading: true,

            };
        }

        case actionType.GET_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                isGetDocLoading: false,
                jobApplicationDocuments: action.payload.jobApplicationDocuments,
                jobApplicationDisclosures: action.payload.jobApplicationDisclosures,
                canApplicantProceed: action.payload.canApplicantProceed,
                isDLAndMECMandatory: action.payload.isDLAndMECMandatory,
                isDLFrontMandatory: action.payload.isDLFrontMandatory,
                isDLBackMandatory: action.payload.isDLBackMandatory,
                isMecMandatory: action.payload.isMecMandatory,
            };
        }

        case actionType.UPDATE_DOCUMENT: {
            return {
                ...state,
                isUpdateDocLoading: true,
                updateResponse: {}
            };
        }

        case actionType.UPDATE_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isUpdateDocLoading: false,
                updateResponse: action.payload,
                isDocDeleted: true
            };
        }

        case actionType.DOWNLOAD_DOCUMENT: {
            return {
                ...state,
                isDownloadDocumentLoading: true,

            };
        }

        case actionType.DOWNLOAD_DOCUMENT_SUCCESS: {
            return {
                ...state,
                isDownloadDocumentLoading: false,
                downloadResponse: action.payload,
            };
        }

        case actionType.UPDATE_OR_UPLOAD_RESPONSE_ERROR_CLOSE: {
            return {
                ...state,
                uploadResponse: {},
                updateResponse: {}
            }
        }

        case actionType.COMMON_ERROR: {
            return {
                ...state,
                errorMessage: action.payload.error,
                errorStatus: true,
                isGetDocLoading: false,
                isUpdateDocLoading: false,
                isLUTLoading: false,
                isUpLoading: false,
                isDownloadDocumentLoading: false,
            }
        }

        case actionType.COMMON_ERROR_CLOSE: {
            return {
                ...state,
                errorStatus: false
            }
        }

        case  actionType.GET_APPLICATION_SUBMITTED_STATUS:{
            return {
                ...state,
                jobApplicationStatus: action.payload
                  }
           }

        default: {
            return state;
        }
    }
};

export default uploadDocument;