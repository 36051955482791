import * as actionType from 'actions';

export const getReviewInfo = (reviewInfo, key) => ({
    type: actionType.GET_REVIEW_INFO,
    payload: reviewInfo,
    key: key,
});

export const getReviewInfoSuccess = (reviewInfoResponse) => ({
    type: actionType.GET_REVIEW_INFO_SUCCESS,
    payload: reviewInfoResponse,
});

export const postReviewInfo = (reviewInfo, key) => ({
    type: actionType.POST_REVIEW_INFO,
    payload: reviewInfo,
    key: key,
});

export const postReviewInfoSuccess = (reviewInfoResponse) => ({
    type: actionType.POST_REVIEW_INFO_SUCCESS,
    payload: reviewInfoResponse,
});