import * as actionType from 'actions';

export const getPersonalInfo = (personalInfo, key) => ({
    type: actionType.GET_PERSONAL_INFO,
    payload: personalInfo,
    key: key,
});

export const getPersonalInfoSuccess = (personalInfoResponse) => ({
    type: actionType.GET_PERSONAL_INFO_SUCCESS,
    payload: personalInfoResponse,
});

export const postPersonalInfo = (personalInfo, key) => ({
    type: actionType.POST_PERSONAL_INFO,
    payload: personalInfo,
    key: key,
});

export const postPersonalInfoSuccess = (personalInfoResponse) => ({
    type: actionType.POST_PERSONAL_INFO_SUCCESS,
    payload: personalInfoResponse,
});

export const savePersonalInfo = (personalInfo) => ({
    type: actionType.SAVE_PERSONAL_INFO,
    payload: personalInfo,
});

export const getEmploymentHistory = (employmentInfo, key) => ({
    type: actionType.GET_EMPLOYMENT_HISTORY,
    payload: employmentInfo,
    key: key,
});

export const getEmploymentHistorySuccess = (employmentInfoResponse) => ({
    type: actionType.GET_EMPLOYMENT_HISTORY_SUCCESS,
    payload: employmentInfoResponse,
});

export const postEmploymentHistory = (employmentInfo, key) => ({
    type: actionType.POST_EMPLOYMENT_HISTORY,
    payload: employmentInfo,
    key: key,
});

export const postEmploymentHistorySuccess = (employmentInfoResponse) => ({
    type: actionType.POST_EMPLOYMENT_HISTORY_SUCCESS,
    payload: employmentInfoResponse,
});

export const getJobApplicationLUT = (jobApplicationLUTRequest) => ({
    type: actionType.GET_JOB_APPLICATION_LUT,
    payload: jobApplicationLUTRequest
});

export const getJobApplicationLUTSuccess = (jobApplicationLUTResponse) => ({
    type: actionType.GET_JOB_APPLICATION_LUT_SUCCESS,
    payload: jobApplicationLUTResponse,
});

export const getExperienceQualification = (expInfo, key, lut) => ({
    type: actionType.GET_EXPERIENCE_QUALIFICATION,
    payload: expInfo,
    key: key,
    lut: lut,
});

export const getExperienceQualificationSuccess = (expInfoResponse) => ({
    type: actionType.GET_EXPERIENCE_QUALIFICATION_SUCCESS,
    payload: expInfoResponse,
});

export const postExperienceQualification = (expInfo, key) => ({
    type: actionType.POST_EXPERIENCE_QUALIFICATION,
    payload: expInfo,
    key: key,
});

export const postExperienceQualificationSuccess = (expInfoResponse) => ({
    type: actionType.POST_EXPERIENCE_QUALIFICATION_SUCCESS,
    payload: expInfoResponse,
});

export const updateApplicantName = () => ({
    type: actionType.UPDATE_APPLICANT_NAME,
});

export const resetJobApplication = () => ({
    type: actionType.RESET_JOB_APPLICATION
});

export const resetAllSteps = () => ({
    type: actionType.RESET_ALL_STEPS
});

export const resetPersonalInfo = () => ({
    type: actionType.RESET_PERSONAL_INFO
});

export const resetEmploymentHistory = () => ({
    type: actionType.RESET_EMPLOYMENT_HISTORY
});

export const resetExperienceQualification = () => ({
    type: actionType.RESET_EXPERIENCE_QUALIFICATIONS
});

export const getScreeningCriteria = (jobInfo) => ({
    type: actionType.GET_SCREENING_CRITERIA,
    payload: jobInfo
});

export const getScreeningCriteriaSuccess = (screeningCriteriaResponse) => ({
    type: actionType.GET_SCREENING_CRITERIA_SUCCESS,
    payload: screeningCriteriaResponse
});

export const updateJobApplicationStatus = (jobInfo) => ({
    type: actionType.UPDATE_JOB_APPLICATION_STATUS,
    payload: jobInfo,
});

export const getJobApplicationSubmittedStatus = (applicationSubmittedStatus) => ({
    type: actionType.GET_APPLICATION_SUBMITTED_STATUS,
    payload: applicationSubmittedStatus
});