import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';
const darkBlue = '#0d3859'
const yellow = '#f3ba00';

export default {
    black,
    white,
    yellow,
    darkBlue,
    primary: {
        contrastText: white,
        dark: '#12568f',
        main: darkBlue,
        light: '#12568f',
        high: '#548AC5',
    },
    secondary: {
        contrastText: white,
        dark: '#12568f',
        main: '#428BCA',
        light: '#428BCA'
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
    },
    text: {
        primary: '#333333',
        secondary: colors.blueGrey[600],
        link: colors.blue[600],
        base: '#6A6B6E',
        light: '#545454',
    },
    grey: {
        dark: '#00000094',
        light: '#F9FAFC',
        neutral: '#2B2B2BAD',
        outline: '#2B2B2B1F'
    },
    mvrDocumentButtons: {
        main: 'rgba(0, 0, 0, 0.6)'
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: '#F4F6F8',
        paper: white,
        light: '#FFFFFFE5',
        lightBlue: '#EBF7FF',
    },
    divider: colors.grey[200]
};