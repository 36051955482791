import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import { instance } from "utils/axios";

export function EncryptPayloadData(apiName, payload, auth){
    const [key, iv, count] = GenerateAesKey(apiName);
    const encryptedData = EncryptDataAes(JSON.stringify(payload), iv, key)
    const keyByte = Array.from(key.sigBytes > 0 ? key.words.reduce((acc, val) => acc.concat([val >>> 24, (val >>> 16) & 0xff, (val >>> 8) & 0xff, val & 0xff]), []) : []); 
    const ivByte = Array.from(iv.sigBytes > 0 ? iv.words.reduce((acc, val) => acc.concat([val >>> 24, (val >>> 16) & 0xff, (val >>> 8) & 0xff, val & 0xff]), []) : []); 
    const aesKey = keyByte+'|'+ivByte;
    const encryptedKey = EncryptDataRsa(aesKey, auth.publicToken, auth.appStartDate);
    const sessionKeys = {"key":key, "iv":iv, "count": count}
    sessionStorage.setItem(apiName, JSON.stringify(sessionKeys));

    return [encryptedData, encryptedKey]
}

export function DecryptResponseData(data, apiName){
    const keys = JSON.parse(sessionStorage.getItem(apiName));

    var decryptedData = JSON.parse(DecryptDataAes(data, keys.iv, keys.key));
        
    if(keys.count === 0) {
        sessionStorage.removeItem(apiName)
    }
    else{
        keys.count = keys.count -1; sessionStorage.setItem(apiName, JSON.stringify(keys))
    }
    
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return decryptedData
}

function EncryptDataRsa(data, key, salt) {
    var public_Key = GetEncryptDecryptKey(key, salt)
    var base64string = GetBase64String(data)
    var encryptedData = EncryptBase64String(base64string, public_Key);
    
    return encryptedData;
}

function EncryptBase64String(data, key) {
    var encryptString = new JSEncrypt();

    encryptString.setPublicKey(key);

    let encryptedString = encryptString.encrypt(data);

    return  encryptedString;
}

function GetBase64String(data) {
    var base64Data = Buffer.from(data).toString('base64');

    return base64Data;
}

function GetEncryptDecryptKey(key, salt){
    var keySalt = Buffer.from(salt).toString('base64');
    var data = key.slice(0,key.search(keySalt));

    return Buffer.from(data, 'base64').toString('utf8') 
}

function GenerateAesKey(apiName){
    if(JSON.parse(sessionStorage.getItem(apiName)) === null){
        const key = CryptoJS.lib.WordArray.random(32);
        const iv = CryptoJS.lib.WordArray.random(16);

        return [key,iv, 0];
    }
    else{
        var keys = JSON.parse(sessionStorage.getItem(apiName));
        return [keys.key, keys.iv, keys.count+1];
    }
    
}

function EncryptDataAes(data, iv, key) {
    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return encrypted.toString();
}

function DecryptDataAes(encryptedData, iv, key) {
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}
