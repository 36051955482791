import { instance } from "utils/axios";

export const getPersonalInfoServices = async personalInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = personalInfo.key;
    return await instance.post('api/wizard/GetJobApplication', personalInfo.payload);
};

export const postPersonalInfoServices = async personalInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = personalInfo.key;
    return await instance.post('api/wizard/UpdateJobApplication', personalInfo.payload);
};

export const getEmploymentHistoryServices = async employmentHistory => {
    instance.defaults.headers.post['JJKAuthTF'] = employmentHistory.key;
    return await instance.post('api/wizard/GetJobApplication', employmentHistory.payload);
};

export const postEmploymentHistoryServices = async employmentHistory => {
    instance.defaults.headers.post['JJKAuthTF'] = employmentHistory.key;
    return await instance.post('api/wizard/UpdateJobApplication', employmentHistory.payload);
};

export const getJobApplicationLUTServices = async jobApplicationLUTRequest => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetJobApplicationLookUpData', jobApplicationLUTRequest);
};

export const getExperienceQualificationServices = async expInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = expInfo.key;
    return await instance.post('api/wizard/GetJobApplication', expInfo.payload);
};

export const postExperienceQualificationServices = async expInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = expInfo.key;
    return await instance.post('api/wizard/UpdateJobApplication', expInfo.payload);
};

export const getExistingUserServices = async data => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetStatusForSubmittedApplication', data);
};

export const getScreeinigCriteriaServices = async personalInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetJobOpeningConfiguration', personalInfo);
};

export const updateJobApplicationStatusServices = async data => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/UpdateJobApplicationStatus', data);
};