import { instance } from "utils/axios";

export const deleteDisclosureServices = async jobApplicationGuid => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/UpdateJobApplicationDisclosures', jobApplicationGuid);
};

export const getDisclosureServices = async disclosureInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = disclosureInfo.key;
    return await instance.post('api/wizard/GetDisclosuresDocument', disclosureInfo.payload);
};

export const postDisclosureServices = async disclosureData => {
    instance.defaults.headers.post['JJKAuthTF'] = disclosureData.key;
    return await instance.post('api/wizard/SaveJobApplicationDisclosure', disclosureData.payload);
};

export const getDisclosureTypesServices = async guidInfo => {
    instance.defaults.headers.post['JJKAuthTF'] = "";
    return await instance.post('api/wizard/GetDisclosureTypes', guidInfo);
};

export const getMvrDocumentsServices = async mvrDoc => {
    instance.defaults.headers.post['JJKAuthTF'] = mvrDoc.key;
    return await instance.post('api/wizard/GetMvrDocumentsForJobApplication', mvrDoc.payload);
};

export const uploadDisclosureServices = async disclosure => {
    instance.defaults.headers.post['JJKAuthTF'] = disclosure.uploadDisclosureKey;
    return await instance.post('api/wizard/UploadApplicationDisclosure', disclosure.uploadDisclosurePayload);
};

export const updateDisclosureServices = async disclosure => {
    instance.defaults.headers.post['JJKAuthTF'] = disclosure.updateDisclosureKey;
    return await instance.post('api/wizard/UpdateJobApplicationDisclosure', disclosure.updateDisclosurePayload)
}