import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid} from '@material-ui/core';
import ModalCustom from 'components/ModalCustom';


const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        position: 'fixed',
        bottom: '0',
        left: '0',
        width: '100%',
        backgroundColor: '#000',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1,2,2,0),
        },
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            padding: theme.spacing(2,2,2,0),
        },
    },
    logoLink: {
        lineHeight: 0
    },
    heading: {
        margin: '0 auto',
        color: theme.palette.white
    },
    leftText: {
        color: '#fff',
        marginLeft: '10px',
        padding: '2px 0',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        }
    },
    rightText: {
        color: '#fff',
        display: 'inline-block',
        verticalAlign: 'right',
        textAlign: 'right',
        marginLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        }
    },
    right: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(0.5),
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    left: {
        textAlign: 'left',
        padding: '0px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(0.5),
            paddingTop: theme.spacing(3),
        },
    },
    chat: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '40px',
        [theme.breakpoints.down('xs')]: {
            width: '25px',
        }
    },
    gridItem: {
        display: '-webkit-inline-box',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexWrap: 'wrap',
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    links: {
        width: 'fit-content',
        color: '#fff',
        display: "flex",
        justifyContent: "space-between",
        listStyle: "none",
        cursor: 'pointer',
        padding: '1px 0',
        textAlign: 'right',
        marginLeft: '10px',
        verticalAlign: 'right',
        fontSize: '14.5px',
        listStyle: "none",
    },
    linkFont: {
        color: '#fff',
        fontFamily: 'Helvetica Neueu, Helvetica, Arial, sans-serif',
        fontSize: '12px',
        marginLeft: '10px',
        fontWeight: "100",
        lineHeight: '21px',
        letterSpacing: '-0.05px',
        listStyle: "none",
        paddingTop:'14px',
        cursor:  "pointer",
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        }
    },
    contentText: {
        fontFamily: 'Helvetica Neueu, Helvetica, Arial, sans-serif',
        color: "#a1a1a1",
        lineHeight: '1.9',
        fontWeight: "200",
        borderBottom: "#000000 thin solid",
        marginBottom: "1%",
    },
    contentBody: {
        fontFamily: 'Helvetica Neueu, Helvetica, Arial, sans-serif',
        color: "#a1a1a1",
        lineHeight: '1.9',
        fontSize: '18px',
        fontWeight: "200",
        borderBottom: "#000000 thin solid",
        marginBottom: "1%",
        [theme.breakpoints.down('xs')]: {
            overflowWrap: 'break-word',
        },
    },
    unAuthContentText: {
        borderBottom: "thin-solid",
        marginBottom: '1%',
        fontFamily: 'Helvetica Neueu, Helvetica, Arial, sans-serif',
        color: "#a1a1a1",
        marginbottom: "8px",
        lineHeight: '1.9',
        letterspacing: '0.05px',
        fontSize: '18px',
        fontWeight: "100",
    },
    titleTextalign: {
        textAlign: "center",
        width: "100%",
        color: "#a1a1a1",
        fontWeight: "400",
        margin: "20px 0 10px",
        fontSize: '38px',
        fontFamily: 'Helvetica Neueu, Helvetica, Arial, sans-serif',
        lineHeight: '.8',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
        }
    }
}));

const Footer = () => {    
    const [unauth, setUnauth] = useState(false)
    const [contact, setContact] = useState(false)
    const classes = useStyles();
    const [ modalTitle, setModalTitle] = useState('')

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setUnauth(false);
        setContact(false);
        setModalTitle('')

    };
    const handleUnauth = () => {
        setModalTitle('Unauthorized Use')
        setUnauth(true)
        handleOpen()
    }
    const handleContactus = () => {
        setModalTitle('Contact Us')
        setContact(true);
        handleOpen()
    }

    const footerLinks = [
        {
            name: 'Terms of Use',
            link: 'https://www.jjkeller.com/learn/terms-conditions',
            isRedirect: true
        },
        {
            name: 'Privacy and Security',
            link: 'https://www.jjkeller.com/learn/privacy-policy',
            isRedirect: true
        },
        {
            name: 'Consumer Information Privacy Policy',
            link: 'https://cdn.jjkeller.com/wcsstore/CVCatalogAssetStore/references/miscellaneous/JJKeller-Consumer-Information-Privacy-Policy.pdf',
            isRedirect: true
        },
        {
            name: 'Contact',
            isRedirect: false
        },
        {
            name: 'Unauthorized Use',
            isRedirect: false
        }

    ]
    const today = new Date();
    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                <Grid item xs={8} sm={12} md={6} className={classes.gridItem} >
                    {
                        footerLinks?.map(item =>
                            <Typography className={classes.linkFont} variant="body1">
                                {
                                    item?.isRedirect === true &&
                                    <a className={classes.linkFont} href={item.link} target={"_blank"} >{item.name}</a>
                                }
                                {
                                    item?.isRedirect === false &&
                                    <a className={classes.linkFont} onClick={item.name === 'Contact' ? handleContactus : handleUnauth}>{item.name}</a>
                                }
                            </Typography>
                        )
                    }
                    <ModalCustom className={classes.titleTextalign}
                        title={<h2 className={classes.titleTextalign}>{modalTitle}</h2>}
                        open={open}
                        close={handleClose}
                        actions={handleContactus}
                        backDrop={true}
                        width="md"
                        buttonText="Close"
                        type="button"
                        submit={handleClose}>
                        {
                            unauth &&
                            <p className={classes.unAuthContentText}>Unauthorized use of this system is prohibited and may result in denial of access,<br /> disciplinary action and/or legal action. Users have no expectation of privacy and J. J. Keller <br />and third parties may monitor and review user activity, including files and e-mails.</p>
                        }
                        {
                            contact &&
                            <div>
                                <div>
                                    <div className={classes.contentText}>
                                        <p><h2><strong>Applicant Help</strong></h2></p>
                                    </div>
                                    <div className={classes.contentBody}>
                                        <p><h3><strong>Managed Services</strong></h3></p>
                                        <p>
                                            <h3><strong>Phone:</strong></h3>
                                            855-426-6759
                                        </p>
                                        <p>
                                            <h3><strong>Technical Support Phone:</strong></h3>
                                            (844)-803-0171
                                        </p>
                                        <p>
                                            <h3><strong>Email:</strong></h3>
                                            jjkellerclientcenter@jjkeller.com
                                        </p>
                                        <p>
                                            <p><h3><strong>Address:</strong></h3></p>
                                            J. J. Keller &amp; Associates, Inc.<br />
                                            Managed Services, MS #1520<br />
                                            3003 Breezewood Lane<br />
                                            Neenah, WI 54957-0368<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
}
                    </ModalCustom>
                </Grid>
                <Grid item xs={12} md className={classes.right}>
                    <Typography className={classes.rightText} variant="body1">
                        Powered by J. J. Keller &amp; Associates
                    </Typography>
                    <img
                        alt="Chat"
                        className={classes.chat}
                        src="/images/chat.png"
                    />
                </Grid>
            </Grid>
            <Grid>
                <Grid item xs className={classes.left}>
                    <Typography className={classes.leftText}> 
                        Copyright {today. getFullYear()} J. J. Keller &amp; Associates, Inc. All rights
                        reserved.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;