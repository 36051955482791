import { takeEvery, takeLatest, take, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';
import { downloadUploadedDocument } from 'utils/settings';
import { DecryptResponseData } from 'EncryptionDecryption/EncryptAndDecrypt';

function* uploadDocumentLUTSaga() {
    try {
        const response = yield call(services.uploadDocumentLUTServices);
        yield put(actionType.uploadDocumentLUTSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUploadDocumentLUTSaga() {
    yield takeLatest(actionType.UPLOAD_DOCUMENT_LUT, uploadDocumentLUTSaga);
}

function* uploadDocumentSaga(document) {
    try {
        const response = yield call(services.uploadDocumentServices, document);
        response.data = DecryptResponseData(response.data, "uploadDocument");

        yield put(actionType.uploadDocumentSuccess(response.data));

        if (response.data.status == true) {
            const response = yield call(services.getAllDocumentsServices, document);
            response.data = DecryptResponseData(response.data, "getAllDocuments");

            yield put(actionType.getAllDocumentsSuccess(response.data));
        }
        else if(response.data.status === false
            && (response.data.message === "Application submitted" 
            || response.data.message === "Application has been already submitted" ))
        {
            yield put(actionType.getJobApplicationSubmittedForUploadStatus(response.data.status));
        }
        else{
            yield put(actionType.uploadDocumentFailed(response.data)); 
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUploadDisclosureSaga() {
    yield takeLatest(actionType.UPLOAD_DISCLOSURE, uploadDisclosureSaga);
}

function* uploadDisclosureSaga(disclosure) {
    try {
        const response = yield call(services.uploadDisclosureServices, disclosure);
        response.data = DecryptResponseData(response.data, "uploadDisclosure");

        yield put(actionType.uploadDisclosureSuccess(response.data));

        if (response.data.status == true) {
            const response = yield call(services.getAllDocumentsServices, disclosure);
            response.data = DecryptResponseData(response.data, "getAllDocuments");

            yield put(actionType.getAllDocumentsSuccess(response.data));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUploadDocumentSaga() {
    yield takeLatest(actionType.UPLOAD_DOCUMENT, uploadDocumentSaga);
}

function* updateDocumentSaga(document) {
    try {
        const response = yield call(services.updateDocumentServices, document);
        response.data = DecryptResponseData(response.data, "updateDocument");

        yield put(actionType.updateDocumentSuccess(response.data));

        if (response.data.status == true) {
            const response = yield call(services.getAllDocumentsServices, document);
            response.data = DecryptResponseData(response.data, "getAllDocuments");

            yield put(actionType.getAllDocumentsSuccess(response.data));
        }
        else if(response.data.status === false
            && (response.data.message === "Application submitted" 
            || response.data.message === "Application has been already submitted" ))
        {
            yield put(actionType.getJobApplicationSubmittedForUploadStatus(response.data.status));
        }
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchUpdateDocumentSaga() {
    yield takeLatest(actionType.UPDATE_DOCUMENT, updateDocumentSaga);
}

function* getAllDocumentsSaga(document) {
    try {
        const response = yield call(services.getAllDocumentsServices, document);

        response.data = DecryptResponseData(response.data, "getAllDocuments");

        yield put(actionType.getAllDocumentsSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchGetAllDocumentsSaga() {
    yield takeLatest(actionType.GET_DOCUMENTS, getAllDocumentsSaga);
}

function* downloadDocumentSaga(document) {
    try {
        const response = yield call(services.downloadDocumentServices, document);
        response.data = DecryptResponseData(response.data, "downloadDocument");

        if (response.data.status) {
            downloadUploadedDocument(response.data);
        }
        yield put(actionType.downloadDocumentSuccess(response.data));
    }
    catch (e) {
        yield put(actionType.commonError({
            error: 'Something went wrong'
        }));
    }
}

function* watchDownloadDocumentSaga() {
    yield takeLatest(actionType.DOWNLOAD_DOCUMENT, downloadDocumentSaga);
}

const uploadDocument = [
    fork(watchGetAllDocumentsSaga),
    fork(watchUpdateDocumentSaga),
    fork(watchUploadDocumentLUTSaga),
    fork(watchUploadDocumentSaga),
    fork(watchDownloadDocumentSaga),
    fork(watchUploadDisclosureSaga)
];

export default uploadDocument;